import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { fixedForwardRef } from '@/utilities/forwardRefFix';

export type TextFieldProps = MuiTextFieldProps;

export const TextField = fixedForwardRef<HTMLDivElement, TextFieldProps>(
  ({ id, label, ...rest }: TextFieldProps, ref): JSX.Element => {
    return <MuiTextField id={id} label={label} variant="standard" {...rest} ref={ref} />;
  },
);
