import { DateTime } from 'luxon';
import { EnvironmentalMonitorChartDatum } from 'models';

export const MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT = 'MMM';

export const getPreviousTwelveMonthUTCDateRange = (
  excludeCurrentMonth = true,
): { beginningOfTheMonthStartUtcIsoDate: string; endOfTheMonthEndUtcIsoDate: string } => {
  const beginningOfTheMonthStartUtcIsoDate = DateTime.utc()
    .startOf('month')
    .minus({ month: excludeCurrentMonth ? 13 : 12 })
    .toISODate();
  const endOfTheMonthEndUtcIsoDate = DateTime.utc()
    .startOf('month')
    .minus({ month: excludeCurrentMonth ? 1 : 0 })
    .endOf('month')
    .toISODate();
  return { beginningOfTheMonthStartUtcIsoDate, endOfTheMonthEndUtcIsoDate };
};

export const getSetOfExpectedBeginningOfMonthUtcIsoDates = (
  beginningOfMonthStartUtcIsoDate: string,
  endOfMonthEndUtcIsoDate: string,
): Set<string> => {
  const setOfExpectedUtcIsoDates = new Set<string>();
  const endUtcDateTime = DateTime.fromISO(endOfMonthEndUtcIsoDate, { zone: 'utc' });
  let currentUtcDateTime = DateTime.fromISO(beginningOfMonthStartUtcIsoDate, { zone: 'utc' });
  while (currentUtcDateTime.toUnixInteger() <= endUtcDateTime.toUnixInteger()) {
    setOfExpectedUtcIsoDates.add(currentUtcDateTime.startOf('month').toISODate());
    currentUtcDateTime = currentUtcDateTime.plus({ month: 1 });
  }
  return setOfExpectedUtcIsoDates;
};

export const getChartDataForEachMissingBeginningOfTheMonthUtcIsoDate = (
  setOfMissingExpectedUtcIsoDateTimes: Set<string>,
): (readonly [localIsoDate: string, datum: EnvironmentalMonitorChartDatum])[] => {
  const unsortedMissingExpectedChartData: (readonly [
    localIsoDate: string,
    datum: EnvironmentalMonitorChartDatum,
  ])[] = [];
  setOfMissingExpectedUtcIsoDateTimes.forEach((missingBeginningOfTheMonthUtcIsoDate) => {
    const currentLocalDateTime = DateTime.fromISO(missingBeginningOfTheMonthUtcIsoDate, {
      zone: 'utc',
    }).toLocal();

    const missingEntryChartDatum: EnvironmentalMonitorChartDatum = {
      timeIntervalLabel: currentLocalDateTime.month,
      avg: undefined,
      minMax: undefined,
    };
    unsortedMissingExpectedChartData.push([
      currentLocalDateTime.toISODate(),
      missingEntryChartDatum,
    ] as const);
  });
  return unsortedMissingExpectedChartData;
};
