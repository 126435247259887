import { RoomData } from 'models/globalHomeModels';
import { GlobalHomeService } from 'services/GlobalHomeService';

export const getAllRoomsAirQualitySensorData = async () => {
  const roomsData: RoomData[] = [];
  const roomsResponse = await GlobalHomeService.getAllRooms();
  if (!roomsResponse) {
    return;
  }

  for await (const room of roomsResponse.data) {
    const roomData: RoomData = { roomId: room.id, roomName: room.nick_name };

    const things = await GlobalHomeService.getThings(room.id);
    const airQualitySensorsThing = things.data.find(
      (t) => t.name === 'Sensors' && t.identifier === 'AIR_QUALITY_SENSORS',
    );
    if (airQualitySensorsThing) {
      roomData.airQualitySensorsId = airQualitySensorsThing.id;
    }
    roomsData.push(roomData);
  }
  return roomsData;
};
