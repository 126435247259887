import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { openMeteoApi } from 'services/OpenMeteoApiService';
import { unitsManagementApi } from 'services/UnitsManagementApi';
import { signInApi, usersApi } from 'services/UsersService';

export const store = configureStore({
  reducer: {
    [signInApi.reducerPath]: signInApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [openMeteoApi.reducerPath]: openMeteoApi.reducer,
    [unitsManagementApi.reducerPath]: unitsManagementApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      signInApi.middleware,
      usersApi.middleware,
      openMeteoApi.middleware,
      unitsManagementApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
