import { EnvironmentSensorType } from '@mgh-app/component-library';
import { HistoricalAirQualityAverages } from './historicalEnvironmentalData';

export type HistEnvMonDisplayedExcludedSensorTypes =
  | EnvironmentSensorType.Unknown
  | EnvironmentSensorType.Temperature
  | EnvironmentSensorType.Particulates
  | EnvironmentSensorType.PM1
  | EnvironmentSensorType.PM4
  | EnvironmentSensorType.SoundPressure;

export type HistEnvMonDisplayedSensorTypes = Exclude<
  EnvironmentSensorType,
  HistEnvMonDisplayedExcludedSensorTypes
>;

export const histEnvMonHiddenSensorTypes: { [K in HistEnvMonDisplayedExcludedSensorTypes]: K } = {
  [EnvironmentSensorType.Unknown]: EnvironmentSensorType.Unknown,
  [EnvironmentSensorType.Temperature]: EnvironmentSensorType.Temperature,
  [EnvironmentSensorType.Particulates]: EnvironmentSensorType.Particulates,
  [EnvironmentSensorType.PM1]: EnvironmentSensorType.PM1,
  [EnvironmentSensorType.PM4]: EnvironmentSensorType.PM4,
  [EnvironmentSensorType.SoundPressure]: EnvironmentSensorType.SoundPressure,
};

export type EnvironmentalMonitoringDisplayItem<
  TEnvironmentSensorType extends HistEnvMonDisplayedSensorTypes,
> = {
  title: JSX.Element;
  type: TEnvironmentSensorType;
  icon: JSX.Element;
  historicalAirQualityKey: keyof HistoricalAirQualityAverages;
  trim: number;
  showsAverageAndMeaning: boolean;
};

export type EnvironmentalMonitoringDisplayItems = {
  [EnvironmentalMonitoringDisplayItemType in HistEnvMonDisplayedSensorTypes]: EnvironmentalMonitoringDisplayItem<EnvironmentalMonitoringDisplayItemType>;
};
