import { Box, Modal } from '@mui/material';
import { ReactNode } from 'react';
import {
  alignItems,
  backgroundColor,
  borderRadius,
  display,
  fontSize,
  fontWeight,
  height,
  justifyContent,
  padding,
  textColor,
  twCls,
} from 'style';
import cls from 'classnames';
import { ReactComponent as LogoIcon } from '../../logo.svg';
import { ReactComponent as SmallLogoIcon } from '../../assets/smallLogo.svg';

const dialogStyle = {
  position: 'absolute',
  top: '15%',
  left: '50%',
  minWidth: '40vw',
  transform: 'translateX(-50%)',
  bgcolor: 'white',
  borderRadius: 2,
};

export interface DialogProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children: ReactNode;
  actionButtons?: ReactNode;
  contentClassName?: string;
  footer?: ReactNode;
}

// TODO: Abstract to shared component library

export const Dialog = ({
  open,
  handleClose,
  title,
  children,
  actionButtons,
  contentClassName,
  footer,
}: DialogProps): JSX.Element => {
  return (
    <Modal open={open}>
      <Box sx={dialogStyle}>
        <div
          className={twCls(
            backgroundColor('bg-grey-100'),
            height('h-20'),
            display('flex'),
            alignItems('items-center'),
            justifyContent('justify-between'),
            borderRadius('rounded-t-lg'),
          )}
        >
          <div
            className={twCls(
              padding('pl-5'),
              display('flex', 'lg:hidden'),
              justifyContent('justify-center'),
            )}
          >
            <SmallLogoIcon />
          </div>
          <div
            className={twCls(
              padding('pl-5'),
              display('hidden', 'lg:flex'),
              justifyContent('justify-center'),
            )}
          >
            <LogoIcon />
          </div>
        </div>
        <div className={cls(twCls(padding('p-8')), contentClassName)}>
          {title && <div className={twCls(fontSize('text-2xl'), padding('pb-4'))}>{title}</div>}
          {children}
          <div className={twCls(display('flex'), justifyContent('justify-end'), padding('pt-8'))}>
            {actionButtons ? (
              actionButtons
            ) : (
              <button
                className={twCls(
                  backgroundColor('bg-blue-100'),
                  textColor('text-white'),
                  padding('px-12', 'py-1'),
                  borderRadius('rounded-lg'),
                  fontWeight('font-medium'),
                )}
                onClick={handleClose}
              >
                Close
              </button>
            )}
          </div>
          {footer}
        </div>
      </Box>
    </Modal>
  );
};
