// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTheme = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss').Config} */
module.exports = {
  jit: true,
  content: ['src/**/*.{ts,tsx}'],
  theme: {
    colors: {
      white: '#fff',
      black: '#000',
      'blue-100': 'rgb(35, 150, 253)',
      'blue-200': 'rgb(232, 240, 249)',
      'grey-100': 'rgb(244, 248, 251)',
      'grey-200': 'rgb(232, 240, 249)',
      'grey-300': 'rgb(216, 216, 216)',
      'grey-900': 'rgb(119, 119, 119)',
      'grey-950': 'rgb(47, 47, 47)',
      'red-100': '#d32f2f',
      'red-200': '#ed0000',
      green: '#19b528',
      amber: '#ff6b00',
      transparent: 'transparent',
    },
    dropShadow: {
      home: ['0 5px 6px rgb(104 155 224 / 0.24)'],
    },
    fontFamily: {
      sans: ['Roboto Flex', ...defaultTheme.fontFamily.sans],
    },
    extend: {
      spacing: {
        17: '4.5rem',
      },
      gridTemplateRows: {
        'min-fc': 'minmax(0, max-content) minmax(0, 1fr)',
        'min-fc-min': 'minmax(0, max-content) minmax(0, 1fr) minmax(0, max-content)',
        'min-fc-min-2': 'minmax(0, max-content) minmax(0, 1fr) repeat(2, minmax(0, max-content))',
      },
      gridTemplateColumns: {
        min: 'repeat(1, minmax(0, min-content))',
        'min-2': 'repeat(2, minmax(0, min-content))',
        'min-3': 'repeat(3, minmax(0, min-content))',
        'min-4': 'repeat(4, minmax(0, min-content))',
        'min-5': 'repeat(5, minmax(0, min-content))',
      },
      content: {
        space: '" "',
      },
      boxShadow: {
        'shades-outer': '0pt 4pt 7pt 0pt rgba(0,135,255,0.36)',
        'shades-inner': 'inset -4pt 0pt 5pt 0pt rgba(0,122,255,0.09)',
      },
      maxHeight: {
        112: '28rem',
      },
      minWidth: {
        '44px': '44px',
      },
    },
  },
  plugins: [],
  corePlugins: {
    preflight: true,
  },
};
