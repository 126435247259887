import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

const baseEndpointUrl = 'https://archive-api.open-meteo.com/v1/';

export const EXAMPLE_LATITUDE = 50.867619;
export const EXAMPLE_LONGITUDE = 0.449638;

export interface HistTemperatureRequest {
  lat: number;
  lng: number;
  startDate: string;
  endDate: string;
}

export interface HistTemperatureResponse {
  daily: {
    temperature_2m_max: Array<number>;
    temperature_2m_min: Array<number>;
    time: Array<string>;
  };
  daily_units: {
    temperature_2m_max: string;
    temperature_2m_min: string;
    time: string;
  };
  elevation: number;
  generationtime_ms: number;
  latitude: number;
  longitude: number;
  timezone: string;
  timezone_abbrevation: string;
  utc_offset_seconds: number;
}

export interface HistTemperatureData {
  date: string;
  temperature: number;
}

export const openMeteoApi = createApi({
  reducerPath: 'openMeteoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseEndpointUrl,
  }),
  endpoints: (builder) => ({
    getHistTemperature: builder.query<HistTemperatureData[], HistTemperatureRequest>({
      query: ({ lat, lng, startDate, endDate }) => ({
        url: `era5?latitude=${lat}&longitude=${lng}&start_date=${startDate}&end_date=${endDate}&daily=temperature_2m_max,temperature_2m_min&timezone=Europe%2FLondon`,
        method: 'GET',
      }),
      transformResponse: (response: HistTemperatureResponse) =>
        response.daily.time.reduce<{ prev: number; data: HistTemperatureData[] }>(
          (acc, val, i) => {
            // console.log(acc.prev, response.daily.temperature_2m_max[i]);
            return {
              prev: response.daily.temperature_2m_max[i]
                ? (response.daily.temperature_2m_max[i] + response.daily.temperature_2m_min[i]) / 2
                : acc.prev - Math.random(),
              data: [
                ...acc.data,
                {
                  date: val,
                  temperature: response.daily.temperature_2m_max[i]
                    ? (response.daily.temperature_2m_max[i] +
                        response.daily.temperature_2m_min[i]) /
                      2
                    : acc.prev,
                } as HistTemperatureData,
              ],
            };
          },
          { prev: 10, data: [] },
        ).data,
    }),
  }),
});

export const { useGetHistTemperatureQuery, useLazyGetHistTemperatureQuery } = openMeteoApi;
