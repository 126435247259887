import { SensorsRangeValues } from '../models';
import { baseUrl } from './apiConfig';
import { ApiError, get, post, ResponseBase } from './request';

// TODO: Refactor ResponseBase to generic to avoid needless proliferation of this type of extension interface:
export interface UnitResponse extends ResponseBase {
  data: UnitData;
}

export interface UnitData {
  id: string;
  name: string;
  unit_type: string;
  property_id: string;
  building_id: string;
  physical_location: string;
}

export interface RoomsResponse extends ResponseBase {
  error: boolean;
  data: RoomData[];
}

export interface RoomData {
  id: string;
  nick_name: string;
}

export interface ThingsResponse extends ResponseBase {
  data: ThingData[];
}

export interface ThingData {
  id: string;
  name: string;
  identifier: string;
}

export interface SensorsResponse extends ResponseBase {
  data: SensorData[];
}

export interface SensorData {
  label: string;
  rh?: number;
  co2?: number;
  dew?: number;
  lux?: number;
  voc?: number;
  pm1?: number;
  pm25?: number;
  pm4?: number;
  pm10?: number;
  air_pressure?: number;
  sound_pressure?: number;
  temp?: number;
}

export interface EnergyResponse extends ResponseBase {
  data: EnergyData;
}

export interface DetailedEnergyResponse extends ResponseBase {
  data: EnergyCircuitData;
}

export interface EnergyCircuitData {
  [id: string]: EnergyData;
}

export interface EnergyData {
  days: number[];
  months: number[];
  name: string;
  type: string;
  status: string;
}

export interface SensorsRangesResponse extends ResponseBase {
  data: SensorsRangeValues;
}

export const GlobalHomeService = {
  getUnitInfo: async (unitId: string): Promise<UnitResponse> =>
    get<UnitResponse, ApiError<ResponseBase>>(`${baseUrl}/properties/units/${unitId}`),

  getAllRooms: async (): Promise<RoomsResponse> =>
    get<RoomsResponse, ApiError<ResponseBase>>(`${baseUrl}/rooms`),

  getThings: async (roomId: string): Promise<ThingsResponse> =>
    post<ThingsResponse, unknown, unknown>(`${baseUrl}/things/list`, {
      roomId: roomId,
    }),

  getRanges: async (): Promise<SensorsRangesResponse> =>
    get<SensorsRangesResponse, ApiError<ResponseBase>>(`${baseUrl}/things/sensors/ranges`),

  getSensors: async (thingId: string): Promise<SensorsResponse> =>
    get<SensorsResponse, ApiError<ResponseBase>>(`${baseUrl}/things/sensors/get/${thingId}`),

  getAllEnergyUsage: async (): Promise<EnergyResponse> =>
    get<EnergyResponse, ApiError<ResponseBase>>(`${baseUrl}/things/energy-usage/get/all`),

  getDetailedEnergyUsage: async (): Promise<DetailedEnergyResponse> =>
    get<DetailedEnergyResponse, ApiError<ResponseBase>>(`${baseUrl}/things/energy-usage/detailed`),
};
