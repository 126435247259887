import { Components } from '@mui/material/styles';
import MuiSwitch, { SwitchProps as MuiSwitchProps, switchClasses } from '@mui/material/Switch';

export type SwitchProps = MuiSwitchProps;

export const SwitchTheme: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      width: 52,
      height: 32,
      padding: 0,
      margin: 8,
    },
    switchBase: {
      padding: 1,
      margin: 1,
      [`&.${switchClasses.checked}, &.${switchClasses.colorPrimary}.${switchClasses.checked}, &.${switchClasses.colorSecondary}.${switchClasses.checked}`]:
        {
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      [`&.${switchClasses.checked}+.${switchClasses.track}`]: {
        opacity: 1,
      },
    },
    thumb: {
      boxSizing: 'border-box',
      width: 28,
      height: 28,
      boxShadow: 'none',
    },
    track: {
      boxSizing: 'border-box',
      borderRadius: 32 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      border: '2px solid #E9E9EA',
    },
  },
};

export const Switch = (props: SwitchProps): JSX.Element => {
  return <MuiSwitch disableRipple {...props} />;
};
