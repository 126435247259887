import { EnvironmentSensorType } from '@mgh-app/component-library';
import { EnvironmentMonitoringItem } from '../EnvironmentMonitoringItem';
import { RoomAirQualitySensorsData } from 'models/globalHomeModels';
import {
  backgroundColor,
  display,
  fontSize,
  gap,
  gridTemplateColumns,
  height,
  margin,
  tailwindClassNameConvertNegative,
  TMargin,
  twCls,
  width,
} from 'style';

export interface AirQualitySensorsContainerProps {
  sensorsData: RoomAirQualitySensorsData[];
}

export const AirQualitySensorsContainer = ({
  sensorsData,
}: AirQualitySensorsContainerProps): JSX.Element => {
  const getGroupedSensors = (sensorTypes: EnvironmentSensorType[]) =>
    sensorsData?.filter((s) => sensorTypes.includes(s.type));

  const getPeak = (sensorTypes: EnvironmentSensorType[]) => {
    const groupedSensors = getGroupedSensors(sensorTypes);
    if (groupedSensors.length === 0) {
      return undefined;
    }

    const maxElement = groupedSensors.reduce((p, v) => {
      return p.value && v.value && p.value > v.value ? p : v;
    });

    return maxElement;
  };

  const getMin = (sensorTypes: EnvironmentSensorType[]) => {
    const groupedSensors = getGroupedSensors(sensorTypes);
    if (groupedSensors.length === 0) {
      return undefined;
    }

    const minElement = groupedSensors.reduce((p, v) => {
      return p.value && v.value && p.value < v.value ? p : v;
    });

    return minElement;
  };

  return (
    <>
      <div
        className={twCls(
          display('grid'),
          gridTemplateColumns('grid-cols-1', 'sm:grid-cols-2', 'md:grid-cols-3', 'xl:grid-cols-5'),
          gap('gap-4'),
        )}
      >
        <EnvironmentMonitoringItem
          title="Carbon Dioxide"
          sensorType={EnvironmentSensorType.CO2}
          peakSensor={getPeak([EnvironmentSensorType.CO2])}
          minSensor={getMin([EnvironmentSensorType.CO2])}
        />
        <EnvironmentMonitoringItem
          title="Humidity"
          sensorType={EnvironmentSensorType.RH}
          peakSensor={getPeak([EnvironmentSensorType.RH])}
          minSensor={getMin([EnvironmentSensorType.RH])}
        />
        <EnvironmentMonitoringItem
          title="VOC"
          sensorType={EnvironmentSensorType.VOC}
          peakSensor={getPeak([EnvironmentSensorType.VOC])}
          minSensor={getMin([EnvironmentSensorType.VOC])}
        />

        <EnvironmentMonitoringItem
          title="Particulates"
          sensorType={EnvironmentSensorType.Particulates}
          peakSensor={getPeak([
            EnvironmentSensorType.PM1,
            EnvironmentSensorType.PM25,
            EnvironmentSensorType.PM4,
            EnvironmentSensorType.PM10,
          ])}
          minSensor={getMin([
            EnvironmentSensorType.PM1,
            EnvironmentSensorType.PM25,
            EnvironmentSensorType.PM4,
            EnvironmentSensorType.PM10,
          ])}
        />
        <EnvironmentMonitoringItem
          title="Temperature"
          sensorType={EnvironmentSensorType.Temperature}
          peakSensor={getPeak([EnvironmentSensorType.Temperature])}
          minSensor={getMin([EnvironmentSensorType.Temperature])}
        />
      </div>
      <div
        className={twCls(
          display('flex'),
          gap('gap-x-5'),
          margin('mt-6', 'ml-1'),
          tailwindClassNameConvertNegative<TMargin>('-mb-12'),
        )}
      >
        <div className={twCls(display('flex'), gap('gap-x-2'))}>
          <div className={twCls(width('w-4'), height('h-4'), backgroundColor('bg-green-100'))} />
          <div className={twCls(fontSize('text-sm'))}>Healthy</div>
        </div>
        <div className={twCls(display('flex'), gap('gap-x-2'))}>
          <div className={twCls(width('w-4'), height('h-4'), backgroundColor('bg-amber-100'))} />
          <div className={twCls(fontSize('text-sm'))}>Acceptable</div>
        </div>
        <div className={twCls(display('flex'), gap('gap-x-2'))}>
          <div className={twCls(width('w-4'), height('h-4'), backgroundColor('bg-amber-200'))} />
          <div className={twCls(fontSize('text-sm'))}>Of Concern</div>
        </div>
      </div>
    </>
  );
};
