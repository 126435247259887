import { ReactElement, ReactNode, useState } from 'react';
import {
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  display,
  fontSize,
  fontWeight,
  justifyContent,
  padding,
  textColor,
  twCls,
  width,
} from 'style';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ReactComponent as ExpandIcon } from 'assets/expandIcon.svg';

export interface AccordionItemProps {
  summary: string;
  children?: ReactNode;
}

export const AccordionItem = ({ summary, children }: AccordionItemProps): JSX.Element => {
  return (
    <Accordion
      className={twCls(
        borderWidth('border'),
        borderRadius('!rounded-lg'),
        borderColor('border-grey-400'),
        boxShadow('!shadow-none'),
        padding('p-2'),
      )}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        className={twCls(fontSize('text-3xl', 'lg:text-4xl'), fontWeight('font-light'))}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export const NonExpandableAccordionItem = ({ summary }: AccordionItemProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(false);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      className={twCls(
        borderWidth('border'),
        borderRadius('!rounded-lg'),
        borderColor('border-grey-400'),
        boxShadow('!shadow-none'),
        padding('p-2'),
      )}
      sx={{
        '.MuiAccordionSummary-root': {
          pointerEvents: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        className={twCls(fontSize('text-3xl', 'lg:text-4xl'), fontWeight('font-light'))}
      >
        {summary}
      </AccordionSummary>
    </Accordion>
  );
};

export interface AccordionItemExtProps {
  summary: ReactElement;
  details: (expanded: boolean) => ReactElement;
}

export const AccordionItemExt = ({ summary, details }: AccordionItemExtProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  return (
    <Accordion
      expanded={expanded}
      className={twCls(
        borderWidth('border'),
        borderRadius('!rounded-lg'),
        borderColor('border-grey-400'),
        boxShadow('!shadow-none'),
        padding('p-2'),
      )}
    >
      <AccordionSummary>
        <div className={(display('flex'), justifyContent('justify-between'), width('w-full'))}>
          <div>{summary}</div>
          <div
            className={twCls(
              fontWeight('font-medium'),
              textColor('text-blue-100'),
              display('flex'),
              justifyContent('justify-end'),
              padding('pt-4'),
            )}
          >
            {!expanded && (
              <button type="button" onClick={handleExpand}>
                View Detailed Readout
              </button>
            )}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div>
          <div>{details(expanded)}</div>
          <div
            className={twCls(
              fontWeight('font-medium'),
              textColor('text-blue-100'),
              display('flex'),
              justifyContent('justify-end'),
            )}
          >
            {expanded && (
              <button type="button" onClick={handleCollapse}>
                Hide Detailed Readout
              </button>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
