import { StyledEngineProvider } from '@mui/material/styles';
import { ReactNode } from 'react';
import { MuiCustomTheme } from './MuiCustomTheme';

export interface LibraryWrapper {
  children: ReactNode;
}

export const LibraryWrapper = ({ children }: LibraryWrapper) => (
  <StyledEngineProvider injectFirst>
    <MuiCustomTheme>{children}</MuiCustomTheme>
  </StyledEngineProvider>
);
