import { EnvironmentSensorType } from '@mgh-app/component-library';
import {
  HistoricalEntry,
  EnvMonitoringAllSensorAccumulator,
  EnvMonitoringAggregatesAccumulator,
  SensorAggregates,
} from 'models';
import { addAccumulators } from '../sharedUtils';

const createAccumulatorFromSensorAggregatesOrDefault = (
  sensorAggregates?: SensorAggregates,
): EnvMonitoringAggregatesAccumulator => {
  if (!sensorAggregates) {
    return {
      runningTotal: 0,
      runningCount: 0,
      runningMax: 0,
      runningMin: 0,
    };
  }
  const accumulator: EnvMonitoringAggregatesAccumulator = {
    runningTotal: sensorAggregates.avg,
    runningMax: sensorAggregates.max,
    runningMin: sensorAggregates.min,
    // TODO: Change to use count when available from backend source.
    runningCount: 1,
  };
  return accumulator;
};

export const createAllSensorTypeAccumulatorFromHistoricalEntry = (
  entry: HistoricalEntry,
): EnvMonitoringAllSensorAccumulator => {
  const fullEntry = new Map<string, SensorAggregates>(Object.entries(entry));

  const accumulator: EnvMonitoringAllSensorAccumulator = {
    co2: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.CO2)),
    dew: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.Dew)),
    rh: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.RH)),
    lux: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.Lux)),
    voc: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.VOC)),
    temp: createAccumulatorFromSensorAggregatesOrDefault(
      fullEntry.get(EnvironmentSensorType.Temperature),
    ),
    air_pressure: createAccumulatorFromSensorAggregatesOrDefault(
      fullEntry.get(EnvironmentSensorType.AirPressure),
    ),
    sound_pressure: createAccumulatorFromSensorAggregatesOrDefault(
      fullEntry.get(EnvironmentSensorType.SoundPressure),
    ),
    pm1: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.PM1)),
    pm25: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.PM25)),
    pm4: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.PM4)),
    pm10: createAccumulatorFromSensorAggregatesOrDefault(fullEntry.get(EnvironmentSensorType.PM10)),
  };

  return accumulator;
};

export const accumulateAllSensorTypesFromHistoricalEntry = (
  entry: HistoricalEntry,
  existing?: EnvMonitoringAllSensorAccumulator,
) => {
  const currentEntryAccumulator = createAllSensorTypeAccumulatorFromHistoricalEntry(entry);

  if (!existing) {
    return currentEntryAccumulator;
  }
  const accumulated: EnvMonitoringAllSensorAccumulator = {
    co2: addAccumulators(
      existing[EnvironmentSensorType.CO2],
      currentEntryAccumulator[EnvironmentSensorType.CO2],
    ),
    dew: addAccumulators(
      existing[EnvironmentSensorType.Dew],
      currentEntryAccumulator[EnvironmentSensorType.Dew],
    ),
    rh: addAccumulators(
      existing[EnvironmentSensorType.RH],
      currentEntryAccumulator[EnvironmentSensorType.RH],
    ),
    lux: addAccumulators(
      existing[EnvironmentSensorType.Lux],
      currentEntryAccumulator[EnvironmentSensorType.Lux],
    ),
    voc: addAccumulators(
      existing[EnvironmentSensorType.VOC],
      currentEntryAccumulator[EnvironmentSensorType.VOC],
    ),
    temp: addAccumulators(
      existing[EnvironmentSensorType.Temperature],
      currentEntryAccumulator[EnvironmentSensorType.Temperature],
    ),
    air_pressure: addAccumulators(
      existing[EnvironmentSensorType.AirPressure],
      currentEntryAccumulator[EnvironmentSensorType.AirPressure],
    ),
    sound_pressure: addAccumulators(
      existing[EnvironmentSensorType.SoundPressure],
      currentEntryAccumulator[EnvironmentSensorType.SoundPressure],
    ),
    pm1: addAccumulators(
      existing[EnvironmentSensorType.PM1],
      currentEntryAccumulator[EnvironmentSensorType.PM1],
    ),
    pm25: addAccumulators(
      existing[EnvironmentSensorType.PM25],
      currentEntryAccumulator[EnvironmentSensorType.PM25],
    ),
    pm4: addAccumulators(
      existing[EnvironmentSensorType.PM4],
      currentEntryAccumulator[EnvironmentSensorType.PM4],
    ),
    pm10: addAccumulators(
      existing[EnvironmentSensorType.PM10],
      currentEntryAccumulator[EnvironmentSensorType.PM10],
    ),
  };

  return accumulated;
};
