import { EnvironmentSensorType } from '@mgh-app/component-library';
import { HistoricalEntry, EnvironmentalMonitorChartDatum, SensorAggregates } from 'models';
import { baseManagementUrl } from 'services';
import { historialEnvironmentalDataApi } from '../sharedUtils';
import {
  getPreviousTwentyFourHoursUTCDateRange,
  getPreviousTwentyFourHourPeriodUTCStartAndEndHours,
  sortPreviousTwentyFourHourPeriodChartData,
  getSetOfExpectedUtcIsoDateTimesHourly,
  getChartDataForEachMissingUtcIsoDateTime,
} from './hourlySharedUtilities';
import { DateTime } from 'luxon';

// UNIT - Hourly - Env Mon Chart Data

export const getUnitHourlyExtensibleAggregationDataForEnvMonChart = async (
  unitId: string,
  type: EnvironmentSensorType,
) => {
  // Get the start and end date for the search.
  const { startUtcIsoDate, endUtcIsoDate } = getPreviousTwentyFourHoursUTCDateRange(false);
  const { startUtcHour, endUtcHour } = getPreviousTwentyFourHourPeriodUTCStartAndEndHours();

  const unitHistoricalEntries: HistoricalEntry[] | undefined = await historialEnvironmentalDataApi<
    HistoricalEntry[]
  >(
    `${baseManagementUrl}/units/${unitId}/historicaldata/air-quality/hourly?start_date=${startUtcIsoDate}&end_date=${endUtcIsoDate}&start_hour=${startUtcHour}&end_hour=${endUtcHour}`,
  );

  if (!unitHistoricalEntries) {
    return null;
  }

  // Populate set containing all utcIsoDateTimes expected in range.
  const setOfExpectedUtcIsoDateTimes = getSetOfExpectedUtcIsoDateTimesHourly(
    startUtcIsoDate,
    startUtcHour,
    endUtcIsoDate,
    endUtcHour,
  );

  let unsortedSortData: (readonly [
    localDateTime: DateTime,
    datum: EnvironmentalMonitorChartDatum,
  ])[] = [];

  unitHistoricalEntries.forEach((entry) => {
    if (entry?.hour === undefined) {
      return;
    }
    // Remove each utcIsoDateTime from expected set for the given range for each utcIsoDateTime encountered in the received data.
    const currentUtcDateTime = DateTime.fromISO(entry.date, { zone: 'utc' }).set({
      hour: entry.hour,
    });

    setOfExpectedUtcIsoDateTimes.delete(currentUtcDateTime.toISO());

    let currentChartDatum = convertHistoricalEntryToHourlyLocalTimeLabeledEnvMonChartDatum(
      entry,
      type,
      entry.hour,
    );

    // The current day average is incomplete, so set it to undefined to indicate this, and ensure a value is not displayed in the chart.
    if (entry.date === DateTime.utc().toISODate() && entry.hour === DateTime.utc().hour) {
      currentChartDatum = { ...currentChartDatum, avg: undefined };
    }

    unsortedSortData.push([currentUtcDateTime.toLocal(), currentChartDatum] as const);
  });

  // Add in missing data chart data.
  unsortedSortData = [
    ...unsortedSortData,
    ...getChartDataForEachMissingUtcIsoDateTime(setOfExpectedUtcIsoDateTimes),
  ];

  const sortedChartData = sortPreviousTwentyFourHourPeriodChartData(unsortedSortData);

  return sortedChartData;
};

const convertHistoricalEntryToHourlyLocalTimeLabeledEnvMonChartDatum = (
  entry: HistoricalEntry,
  type: EnvironmentSensorType,
  hour: number,
): EnvironmentalMonitorChartDatum => {
  const fullEntry = new Map<string, SensorAggregates>(Object.entries(entry));
  const currentSensorTypePartial = fullEntry.get(type);
  if (!currentSensorTypePartial) {
    throw Error('Historical entry conversion attempted on unsupported environmental sensor type');
  }

  const environmentalMonitorChartDatum: EnvironmentalMonitorChartDatum = {
    // Hour property is zero indexed (0-23), so add 1 to show data labeled on chart as spanning from hours 1 to 24.
    timeIntervalLabel:
      DateTime.fromISO(entry.date, { zone: 'utc' }).set({ hour: hour }).toLocal().hour + 1,
    avg: currentSensorTypePartial.avg,
    minMax: [currentSensorTypePartial.min, currentSensorTypePartial.max],
  };

  return environmentalMonitorChartDatum;
};
