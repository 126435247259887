import { Spinner } from '@mgh-app/component-library';
import { EnergyChart, EnergyChartData } from 'components/EnergyChart';
import { Dialog } from 'components/Dialog';
import { EnergyData } from 'models/globalHomeModels';
import { useEffect, useState } from 'react';
import { CircuitType } from 'services/apiConfig';
import { GlobalHomeService } from 'services/GlobalHomeService';
import {
  display,
  flexDirection,
  fontSize,
  fontWeight,
  gap,
  justifyContent,
  margin,
  padding,
  twCls,
  width,
  borderWidth,
  borderColor,
  backgroundColor,
  alignItems,
  borderRadius,
  textOverflow,
  flexWrap,
  verticalAlign,
} from 'style';

import { ReactComponent as HeatingIcon } from 'assets/heating.svg';
import { ReactComponent as LightingIcon } from 'assets/lighting.svg';
import { ReactComponent as SocketsIcon } from 'assets/sockets.svg';
import { FlaggedTextDisplay } from 'components/FlaggedTextDisplay';
import { EPCApiDataEntry, EPCByAddressAndOptionalPostCode } from 'services/EPCService';

const energyRefreshIntervalMs = 60 * 1000; // 1 minute

export interface MyEnergyPanelProps {
  unitAddress?: string[] | null;
  unitPostCode?: string | null;
}

export const MyEnergyPanel = ({ unitAddress, unitPostCode }: MyEnergyPanelProps): JSX.Element => {
  const [heatingEnergyData, setHeatingEnergyData] = useState<EnergyData | null>(null);
  const [lightingEnergyData, setLightingEnergyData] = useState<EnergyData | null>(null);
  const [socketsEnergyData, setSocketsEnergyData] = useState<EnergyData | null>(null);
  const [energyChartData, setEnergyChartData] = useState<EnergyChartData[] | null>(null);
  const [curEnergyEfficiency, setCurEnergyEfficiency] = useState<number | null>(null);
  const [curEnergyRating, setCurEnergyRating] = useState<string | null>(null);
  const [potentialEnergyEfficiency, setPotentialEnergyEfficiency] = useState<number | null>(null);
  const [potentialEnergyRating, setPotentialEnergyRating] = useState<string | null>(null);
  const [noEPCDataFound, setNoEPCDataFound] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      //TODO: Handle error.
      if (!unitAddress) {
        return;
      }
      try {
        const epcResults = await EPCByAddressAndOptionalPostCode(
          unitAddress.join('+'),
          unitPostCode,
        );
        if (epcResults) {
          if (epcResults.rows.length !== 1) {
            console.error('A Multiple Match EPC Error Has Occurred!');
          }
          const matchingEPCEntry: EPCApiDataEntry = epcResults.rows[0];
          setCurEnergyEfficiency(+matchingEPCEntry['current-energy-efficiency']);
          setPotentialEnergyEfficiency(+matchingEPCEntry['potential-energy-efficiency']);
          setCurEnergyRating(matchingEPCEntry['current-energy-rating']);
          setPotentialEnergyRating(matchingEPCEntry['potential-energy-rating']);
        } else {
          setNoEPCDataFound(true);
        }
      } catch {
        setNoEPCDataFound(true);
      }
    })();
  }, [unitAddress, unitPostCode]);

  const getDetailedEnergyData = async () => {
    const energyUsage = await GlobalHomeService.getDetailedEnergyUsage();

    const circuits = energyUsage.data;

    // get total energy usage from all the circuits
    const cDays = [0, 0, 0, 0, 0, 0, 0];
    const cMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (const c in circuits) {
      if (circuits[c].hasOwnProperty('days') && circuits[c].hasOwnProperty('months')) {
        const sDays = circuits[c].days;
        const sMonth = circuits[c].months;
        sDays.forEach((d, i) => {
          cDays[i] = Number((cDays[i] + d).toFixed(2));
        });
        sMonth.forEach((m, i) => {
          cMonths[i] = Number((cMonths[i] + m).toFixed(2));
        });
      }
    }

    const totalEnergyData: EnergyData = {
      current: cDays[0],
      dayAverage: cDays.slice(1).reduce((a, b) => a + b, 0) / 6,
      monthAverage: cMonths.reduce((a, b) => a + b, 0) / cMonths.filter((val) => val !== 0).length,
    };

    // get categorized energy usage
    let heating = 0;
    let lighting = 0;
    let sockets = 0;

    for (const c in circuits) {
      if (circuits[c].hasOwnProperty('type')) {
        switch (circuits[c].type) {
          case CircuitType.Heating:
            heating += circuits[c].days[0];
            break;
          case CircuitType.Lighting:
            lighting += circuits[c].days[0];
            break;
          case CircuitType.Sockets:
            sockets += circuits[c].days[0];
            break;
          default:
            break;
        }
      }
    }

    setHeatingEnergyData({
      current: Number((heating / 1000).toPrecision(3)),
      dayAverage: 0,
      monthAverage: 0,
    });
    setLightingEnergyData({
      current: Number((lighting / 1000).toPrecision(3)),
      dayAverage: 0,
      monthAverage: 0,
    });
    setSocketsEnergyData({
      current: Number((sockets / 1000).toPrecision(3)),
      dayAverage: 0,
      monthAverage: 0,
    });

    setEnergyChartData([
      {
        measureType: 'Your Current Use',
        value: Number((totalEnergyData.current / 1000).toFixed(2)),
      },
      {
        measureType: 'Your Average Use',
        value: Number((totalEnergyData.dayAverage / 1000).toFixed(2)),
      },
    ]);
  };

  const [openEpcRatingDialog, setOpenEpcRatingDialog] = useState(false);
  const handleOpenEpcRatingDialog = () => setOpenEpcRatingDialog(true);
  const handleCloseEpcRatingDialog = () => setOpenEpcRatingDialog(false);

  useEffect(() => {
    (async () => {
      await getDetailedEnergyData();

      const energyDataIntervalCall = setInterval(async () => {
        await getDetailedEnergyData();
      }, energyRefreshIntervalMs);

      return () => {
        // clean up
        clearInterval(energyDataIntervalCall);
      };
    })();
  }, []);

  return (
    <div
      className={twCls(
        display('flex'),
        flexDirection('flex-col', 'lg:flex-row'),
        justifyContent('justify-center'),
      )}
    >
      {energyChartData && heatingEnergyData && lightingEnergyData && socketsEnergyData ? (
        <>
          <div className={twCls(width('w-full', 'lg:w-1/2'), margin('mt-4'))}>
            <EnergyChart chartData={energyChartData} />
          </div>
          <div
            className={twCls(
              width('w-full', 'lg:w-1/2'),
              display('flex'),
              flexDirection('flex-col'),
              justifyContent('justify-center'),
              padding('pl-0', 'lg:pl-10', 'pt-8', 'lg:pt-0'),
            )}
          >
            <div
              className={twCls(
                display('flex'),
                flexDirection('flex-col'),
                alignItems('items-start'),
              )}
            >
              <div className={twCls(width('w-full'))}>
                <h3 className={twCls(fontSize('text-2xl'), fontWeight('font-light'))}>
                  EPC Rating
                </h3>
                <div
                  className={twCls(
                    padding('py-3'),
                    display('flex'),
                    flexDirection('flex-row'),
                    flexWrap('flex-wrap'),
                    textOverflow('truncate'),
                    gap('gap-y-4'),
                    justifyContent('justify-between'),
                  )}
                >
                  {noEPCDataFound ? (
                    <p>Your EPC Data Are Unavailable</p>
                  ) : (
                    <div
                      className={twCls(
                        gap('gap-x-2.5'),
                        display('flex'),
                        flexDirection('flex-row'),
                      )}
                    >
                      <FlaggedTextDisplay
                        primaryContents={
                          <p>
                            <span className={twCls(padding('pr-1'))}>Current </span>
                            <span
                              className={twCls(
                                fontSize('text-sm'),
                                fontWeight('font-semibold'),
                                verticalAlign('align-middle'),
                              )}
                            >
                              {curEnergyRating}
                            </span>
                          </p>
                        }
                        flagContents={<p>{curEnergyEfficiency}</p>}
                      />
                      <FlaggedTextDisplay
                        primaryContents={
                          <p>
                            <span className={twCls(padding('pr-1'))}>Potential </span>
                            <span
                              className={twCls(
                                fontSize('text-sm'),
                                fontWeight('font-semibold'),
                                verticalAlign('align-middle'),
                              )}
                            >
                              {potentialEnergyRating}
                            </span>
                          </p>
                        }
                        flagContents={<p>{potentialEnergyEfficiency}</p>}
                      />
                    </div>
                  )}
                  <FlaggedTextDisplay
                    primaryContents={
                      <p>
                        <span className={twCls(padding('pr-1'))}>Average Rating </span>
                        <span
                          className={twCls(
                            fontSize('text-sm'),
                            fontWeight('font-semibold'),
                            verticalAlign('align-middle'),
                          )}
                        >
                          D
                        </span>
                      </p>
                    }
                    flagContents={<p>67</p>}
                  />
                </div>
              </div>
              <div className={twCls(padding('py-3'))}>
                <button
                  className={twCls(
                    backgroundColor('bg-grey-100'),
                    width('w-48'),
                    padding('px-3', 'py-1'),
                    borderRadius('rounded-lg'),
                    fontWeight('font-medium'),
                  )}
                  onClick={handleOpenEpcRatingDialog}
                >
                  What is an EPC Rating?
                </button>
              </div>
            </div>
            <div
              className={twCls(
                borderWidth('border-t'),
                borderColor('border-grey-400'),
                margin('my-5'),
              )}
            />
            <div className={twCls(fontSize('text-2xl'), fontWeight('font-light'))}>
              Current Usage
            </div>
            <div
              className={twCls(
                display('flex'),
                fontSize('text-base', 'lg:text-lg'),
                padding('pl-1'),
                gap('gap-x-6'),
              )}
            >
              <div className={twCls(display('flex'), margin('mt-2'), gap('gap-x-1.5'))}>
                <HeatingIcon />
                {heatingEnergyData ? heatingEnergyData.current : 'N/A'} kWh
              </div>
              <div className={twCls(display('flex'), gap('gap-x-1.5'))}>
                <LightingIcon />
                <span className={twCls(margin('mt-2'))}>
                  {lightingEnergyData ? lightingEnergyData.current : 'N/A'} kWh
                </span>
              </div>
              <div className={twCls(display('flex'), margin('mt-2'), gap('gap-x-1.5'))}>
                <SocketsIcon />
                {socketsEnergyData ? socketsEnergyData.current : 'N/A'} kWh
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}

      <Dialog
        title="What is an EPC Rating?"
        open={openEpcRatingDialog}
        handleClose={handleCloseEpcRatingDialog}
      >
        <div className={twCls(display('flex'), flexDirection('flex-col'))}>
          <div
            className={twCls(
              display('flex'),
              flexDirection('flex-row'),
              justifyContent('justify-between'),
            )}
          ></div>
          <p className={twCls(padding('px-4', 'pb-4'))}>
            An EPC gives a property an energy efficiency rating from A (most efficient) to G (least
            efficient) and is valid for 10 years. Check how you could make your home more energy
            efficient using the Energy Savings Trust&apos;s home energy check.
          </p>
        </div>
      </Dialog>
    </div>
  );
};
