import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { authFetchBaseQuery, baseManagementUrl } from './apiConfig';
import { Document } from 'models/document';

export interface GetDocumentRequest {
  unitId: string;
}

export interface DocumentsResponse {
  documents: Document[];
}

export interface DocumentsData {
  all: Document[];
  grouped: Record<string, Document[]>;
}

export interface HomeConsentRequest {
  unitId: string;
}

export interface HomeConsent {
  consented: boolean;
  userId?: string;
  updatedAt: string;
}

export interface SetHomeConsentRequest {
  unitId: string;
  consented: boolean;
}

export const unitsManagementApi = createApi({
  reducerPath: 'unitsManagementApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${baseManagementUrl}/units`,
  }),
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentsData, GetDocumentRequest>({
      query: ({ unitId }) => ({ url: `${unitId}/documents` }),
      transformResponse: (response: DocumentsResponse) => ({
        all: response.documents,
        grouped: response.documents.reduce(
          (acc, doc) => ({ ...acc, [doc.type]: [...(acc[doc.type] || []), doc] }),
          {} as Record<string, Document[]>,
        ),
      }),
    }),
    getConsent: builder.query<HomeConsent, HomeConsentRequest>({
      query: ({ unitId }) => ({ url: `${unitId}/consent` }),
    }),
    saveConsent: builder.mutation<HomeConsent, SetHomeConsentRequest>({
      query: ({ unitId, consented }) => ({
        url: `${unitId}/consent`,
        method: 'PUT',
        body: { consented },
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useLazyGetDocumentsQuery,
  useGetConsentQuery,
  useLazyGetConsentQuery,
  useSaveConsentMutation,
} = unitsManagementApi;
