import { Button, TextField } from '@mgh-app/component-library';
import { useAuth } from 'hooks/useAuth';
import { useAppNavigation } from 'hooks/useAppNavigation';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ApiError } from 'services/request';
import {
  display,
  flexDirection,
  fontSize,
  margin,
  padding,
  textAlign,
  textColor,
  twCls,
} from 'style';
import { ReactComponent as LogoIcon } from '../../logo.svg';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

interface Fields {
  email: string;
  password: string;
}

type ErrorTypes = 'required' | 'pattern';

const ErrorHelper = {
  email: {
    required: 'Email Address is required',
    pattern: 'Email Address is incorrect',
  },
  password: {
    required: 'Password is required',
  },
};

export const LoginPage = (): JSX.Element => {
  const { handleSubmit, control } = useForm<Fields>();
  const [customError, setCustomError] = useState<string>();
  const { signIn } = useAuth();
  const { navigateDashboard } = useAppNavigation();

  const login = async ({ email, password }: Fields) => {
    try {
      await signIn(email, password);
      navigateDashboard();
    } catch (err) {
      if (err instanceof ApiError) {
        const error = err as ApiError<unknown>;
        if (Array.isArray(error.response?.message)) {
          setCustomError(error.response?.message.join(', '));
        } else if (error.response?.message) {
          setCustomError(error.response.message);
        } else {
          setCustomError(error.message);
        }
      }
    }
  };

  return (
    <div className={twCls(display('flex'), flexDirection('flex-col'), padding('px-12'))}>
      <LogoIcon />
      <h1
        className={twCls(
          // textColor('text-grey-900'),
          margin('mt-2'),
          textAlign('text-center'),
          fontSize('text-2xl'),
        )}
      >
        Login
      </h1>
      <Controller
        name="email"
        control={control}
        rules={{
          required: true,
          pattern: emailRegex,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            error={error !== undefined}
            helperText={ErrorHelper.email[error?.type as ErrorTypes]}
            className={twCls(margin('mt-28'))}
            label="Email Address"
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            error={error !== undefined}
            helperText={error?.type === 'required' && ErrorHelper.password.required}
            className={twCls(margin('mt-9'))}
            label="Password"
            type="password"
            {...field}
          />
        )}
      />
      <p className={twCls(margin('mt-5'), textAlign('text-right'), textColor('text-blue-100'))}>
        Forgot Password?
      </p>
      <p className={twCls(textColor('text-red-100'), fontSize('text-xs'), margin('mt-10'))}>
        {customError || <>&nbsp;</>}
      </p>
      <Button className={twCls(margin('mt-4'))} onClick={handleSubmit(login)}>
        Login
      </Button>
    </div>
  );
};
