import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';
import { dialogTheme } from './Modal';
import { SwitchTheme } from './Switch';

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto Flex, system-ui, sans-serif',
  },
  palette: {
    primary: {
      main: 'rgb(35, 150, 253)',
    },
  },
  components: {
    MuiSwitch: SwitchTheme,
    MuiDialog: dialogTheme,
  },
});

export interface MuiCustomThemeProps {
  children?: ReactNode;
}

export const MuiCustomTheme = ({ children }: MuiCustomThemeProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
