import {
  alignItems,
  borderColor,
  borderWidth,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  height,
  justifyContent,
  lineHeight,
  margin,
  textAlign,
  twCls,
  width,
} from 'style';

export interface HeaderProps {
  title: string;
  subTitle1?: string;
  subTitle2?: string;
}

export const Header = ({ title, subTitle1, subTitle2 }: HeaderProps): JSX.Element => {
  return (
    <div className={twCls(display('flex'), height('h-fit'))}>
      <div
        className={twCls(
          width('w-fit'),
          textAlign('text-left'),
          fontSize('text-2xl', 'md:text-4xl', 'lg:text-6xl'),
          fontWeight('font-medium'),
          lineHeight('leading-tight'),
          display('flex'),
          alignItems('items-center'),
        )}
      >
        {title}
      </div>
      <div
        className={twCls(borderWidth('border-l', margin('mx-5'), borderColor('border-grey-400')))}
      />
      <div
        className={twCls(
          display('flex'),
          flexDirection('flex-col'),
          justifyContent('justify-center'),
          width('w-auto'),
          fontSize('text-sm', 'md:text-lg', 'lg:text-xl'),
          fontWeight('font-light'),
        )}
      >
        <div>{subTitle1}</div>
        <div>{subTitle2}</div>
      </div>
    </div>
  );
};
