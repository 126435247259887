import { useAuth } from 'hooks/useAuth';
import { getTokensFromLocalStorage, ultraWebAppUrl } from 'services/apiConfig';
import { borderWidth, display, justifyItems, twCls } from 'style';

export const UltraWebAppPage = () => {
  const localStorageTokens = getTokensFromLocalStorage();
  const { user } = useAuth();

  const url = `${ultraWebAppUrl}/app#unitId=${user?.unitId}&token=${localStorageTokens.token}&refreshToken=${localStorageTokens.refreshToken}`;

  return (
    <div className={twCls(display('grid'), justifyItems('justify-items-center'))}>
      <iframe
        title="Ultra Web App"
        src={url}
        width="390px"
        height="844px"
        className={twCls(borderWidth('border-0'))}
      ></iframe>
    </div>
  );
};
