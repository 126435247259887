import { DateTime } from 'luxon';
import { ApiError, ErrorResponse } from './request';

const baseEndpointUrl = 'https://api.ambeedata.com';

export const EXAMPLE_LATITUDE = 50.867619;
export const EXAMPLE_LONGITUDE = 0.449638;

export interface AmbeeApiWeatherHistDataEntry {
  time: number;
  precipIntensity: number;
  precipType: string;
  precipProbability: string;
  uvIndex: number;
  temperature: number;
  apparentTemperature: number;
  summary: string;
  icon: string;
  dewPoint: number;
  humidity: number;
  pressure: number;
  windSpeed: number;
  windGust: number;
  windBearing: number;
  cloudCover: number;
  visibility: number;
  ozone: number;
}

export interface AmbeeApiHistWeatherData {
  lat: number;
  lng: number;
  history: AmbeeApiWeatherHistDataEntry[];
}

export interface AmbeeApiWeatherResponse {
  message: string;
  data: AmbeeApiHistWeatherData;
}

const ambeeApiRequest = async <TResponse, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> => {
  const response = await fetch(url, {
    headers: {
      'x-api-key': window.__RUNTIME_CONFIG__.AMBEE_API_KEY,
      'Content-type': 'application/json',
    },
    ...config,
  });

  const data = await response.json();

  if (response.status >= 400) {
    throw new ApiError(response.statusText, data as ErrorResponse<TError>);
  }

  return data as TResponse;
};

export const AmbeeApiService = {
  getHistWeather: async (lat: number, lng: number, from: DateTime, to: DateTime) =>
    ambeeApiRequest<AmbeeApiWeatherResponse>(
      `${baseEndpointUrl}/weather/history/daily/by-lat-lng?lat=${lat}&lng=${lng}&from=${from.toISODate()} 00:00:00&to=${to.toISODate()} 00:00:00&units=si`,
    ),
};
