import { Grid } from '@mui/material';
import { AccordionItem } from 'components/AccordionItem';
import { FakeFileItem, FileItem } from 'components/FileItem';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const MGHServicesPlanPDF = require('assets/Thorne - Plot 2 - MGH Services Plan.pdf');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MGHServicesSchematicPDF = require('assets/Thorne - Plot 2 - MGH Services Schematic.pdf');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MGHCircuitSchedulePDF = require('assets/Thorne - Plot 2 - Circuit Schedule.pdf');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MGHDistributionBoardSchematicPDF = require('assets/Thorne - Plot 2 - Distribution Board Schematic.pdf');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MGHInstallationGuidePDF = require('assets/Thorne - MyGlobalHome Installation Guide.pdf');

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConstructionInformationPanelProps {}

export const ConstructionInformationPanel =
  ({}: ConstructionInformationPanelProps): JSX.Element => {
    return (
      <AccordionItem summary="Construction Information">
        <Grid container columnSpacing={10} rowSpacing={4}>
          <Grid item>
            <FileItem file={MGHServicesPlanPDF} title="MyGlobalHome" subTitle="Services Plan" />
          </Grid>
          <Grid item>
            <FileItem
              file={MGHServicesSchematicPDF}
              title="MyGlobalHome"
              subTitle="Services Schematic"
            />
          </Grid>
          <Grid item>
            <FileItem
              file={MGHCircuitSchedulePDF}
              title="MyGlobalHome"
              subTitle="Circuit Schedule"
            />
          </Grid>
          <Grid item>
            <FileItem
              file={MGHDistributionBoardSchematicPDF}
              title="MyGlobalHome"
              subTitle="DB Schematic"
            />
          </Grid>
          <Grid item>
            <FileItem
              file={MGHInstallationGuidePDF}
              title="MyGlobalHome"
              subTitle="Installation Guide"
            />
          </Grid>
          <Grid item>
            <FakeFileItem title="MyGlobalHome" subTitle="Electrical Installation Certificate" />
          </Grid>
          <Grid item>
            <FakeFileItem title="MyGlobalHome" subTitle="Gas Safety Certificate" />
          </Grid>
          <Grid item>
            <FakeFileItem title="MyGlobalHome" subTitle="Building Control Completion Certificate" />
          </Grid>
          <Grid item>
            <FakeFileItem title="MyGlobalHome" subTitle="Habitation Certificate" />
          </Grid>
          <Grid item>
            <FakeFileItem title="MyGlobalHome" subTitle="Air Permeability Test Certificate" />
          </Grid>
        </Grid>
      </AccordionItem>
    );
  };
