import { EnvironmentalMonitorChart } from 'components/historical-environmental-monitoring/EnvironmentalMonitorChart';
import { useEffect, useState, useCallback } from 'react';
import type { MouseEvent } from 'react';
import { EnvironmentSensorType, Spinner } from '@mgh-app/component-library';
import {
  alignItems,
  justifyContent,
  display,
  twCls,
  padding,
  inset,
  fontSize,
  lineHeight,
  position,
  textTransform,
  height,
} from '../../../style';
import {
  getRoomDailyExtensibleAggregateDataForEnvMonChart,
  getRoomHourlyExtensibleAggregateDataForEnvMonChart,
  getRoomMonthlyExtensibleAggregateDataForEnvMonChart,
  getUnitDailyExtensibleAggregationDataForEnvMonChart,
  getUnitHourlyExtensibleAggregationDataForEnvMonChart,
  getUnitMonthlyExtensibleAggregationDataForEnvMonChart,
} from 'services';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { EnvironmentalMonitorChartDatum } from 'models';
import { DateTime } from 'luxon';
import { MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT } from 'services';

const toggleButtonStyle = {
  '&.MuiToggleButton-root': {
    color: 'black',
    backgroundColor: '#e9f0f5',
    border: 'solid 2px #e9f0f5',
    borderRadius: '8px',
    paddingX: '1.5rem',
    textTransform: 'none',
    fontSize: '1rem',
  },
  '&.Mui-selected': {
    backgroundColor: 'white',
    fontWeight: 'bold',
  },
};

export interface EnvironmentalMonitorDetailsProps {
  isUnit: boolean;
  unitId: string;
  roomId?: string;
  expanded: boolean;
  unitLabel: string;
  type: EnvironmentSensorType;
}

const environmentalMonitoringChartType = {
  hourly: 'hourly',
  daily: 'daily',
  monthly: 'monthly',
} as const;

type EnvironmentalMonitoringChartType = keyof typeof environmentalMonitoringChartType;

export type EnvironmentalMonitoringChartGetUnitData = (
  unitId: string,
  type: EnvironmentSensorType,
) => Promise<EnvironmentalMonitorChartDatum[] | null>;

const unitEnvironmentalMonitoringChartTypeDataRetrieval: {
  [ChartType in EnvironmentalMonitoringChartType]: EnvironmentalMonitoringChartGetUnitData;
} = {
  hourly: getUnitHourlyExtensibleAggregationDataForEnvMonChart,
  daily: getUnitDailyExtensibleAggregationDataForEnvMonChart,
  monthly: getUnitMonthlyExtensibleAggregationDataForEnvMonChart,
};

export type EnvironmentalMonitoringChartGetRoomData = (
  unitId: string,
  type: EnvironmentSensorType,
  roomId: string | undefined,
) => Promise<EnvironmentalMonitorChartDatum[] | null>;

const roomEnvironmentalMonitoringChartTypeDataRetrieval: {
  [ChartType in EnvironmentalMonitoringChartType]: EnvironmentalMonitoringChartGetRoomData;
} = {
  hourly: getRoomHourlyExtensibleAggregateDataForEnvMonChart,
  daily: getRoomDailyExtensibleAggregateDataForEnvMonChart,
  monthly: getRoomMonthlyExtensibleAggregateDataForEnvMonChart,
};

const chartTypesToGetIsCurrentPeriod: {
  [ChartType in EnvironmentalMonitoringChartType]: (
    timeIntervalLabel: string | number,
    dataLength: number,
    index: number,
  ) => boolean;
} = {
  hourly: (timeIntervalLabel, dataLength, index) =>
    index === dataLength - 1 && DateTime.now().hour + 1 === +timeIntervalLabel,
  daily: (timeIntervalLabel, dataLength, index) =>
    index === dataLength - 1 && DateTime.now().day === +timeIntervalLabel,
  monthly: (timeIntervalLabel, dataLength, index) =>
    index === dataLength - 1 &&
    DateTime.now().toFormat(MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT) === timeIntervalLabel,
};
const chartTypesToTimeIntervalScaleLabelElement: {
  [ChartType in EnvironmentalMonitoringChartType]: JSX.Element;
} = {
  hourly: (
    <p
      className={twCls(
        position('absolute'),
        inset('bottom-0'),
        fontSize('text-sm'),
        lineHeight('leading-7'),
        padding('pb-6', 'pl-3.5'),
      )}
    >
      HOUR
    </p>
  ),
  daily: (
    <p
      className={twCls(
        position('absolute'),
        inset('bottom-0'),
        fontSize('text-sm'),
        lineHeight('leading-7'),
        padding('pb-6', 'pl-6'),
      )}
    >
      DAY
    </p>
  ),
  monthly: (
    <p
      className={twCls(
        position('absolute'),
        inset('bottom-0'),
        fontSize('text-sm'),
        lineHeight('leading-7'),
        padding('pb-6'),
      )}
    >
      MONTH
    </p>
  ),
};

export const EnvironmentalMonitorDetails = ({
  isUnit,
  unitId,
  roomId,
  expanded,
  unitLabel,
  type,
}: EnvironmentalMonitorDetailsProps): JSX.Element => {
  const [chartData, setChartData] = useState<EnvironmentalMonitorChartDatum[] | null>(null);
  const [detailsReportUnavailable, setDetailsReportUnavailable] = useState(false);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [chartType, setChartType] = useState<EnvironmentalMonitoringChartType>(
    environmentalMonitoringChartType.daily,
  );

  useEffect(() => {
    if (!expanded) {
      setChartData(null);
      return;
    }
    (async () => {
      try {
        let results: EnvironmentalMonitorChartDatum[] | null = null;
        results = isUnit
          ? await unitEnvironmentalMonitoringChartTypeDataRetrieval[chartType](unitId, type)
          : await roomEnvironmentalMonitoringChartTypeDataRetrieval[chartType](
              unitId,
              type,
              roomId,
            );
        if (!results) {
          throw new Error(
            `Error accessing historical data details for sensor type ${type} for chart type ${chartType}`,
          );
        }
        setChartData(results);
      } catch (e) {
        setDetailsReportUnavailable(true);
        console.error(e);
      } finally {
        setIsLoadingChartData(false);
      }
    })();
  }, [isUnit, roomId, unitId, expanded, type, chartType]);

  const handleChartTypeChange = useCallback(
    (_e: MouseEvent<HTMLElement>, value: EnvironmentalMonitoringChartType) => {
      if (value !== null) {
        setChartType(value);
        setIsLoadingChartData(true);
      }
    },
    [],
  );

  return (
    <div className={twCls(padding('pb-4'))}>
      {expanded && chartData ? (
        <>
          <div
            className={twCls(
              padding('pb-4', 'pr-12'),
              display('flex'),
              alignItems('items-end'),
              justifyContent('justify-end'),
            )}
          >
            <ToggleButtonGroup value={chartType} exclusive onChange={handleChartTypeChange}>
              <ToggleButton
                value={environmentalMonitoringChartType.hourly}
                size="small"
                sx={toggleButtonStyle}
              >
                <span className={twCls(textTransform('capitalize'))}>
                  {environmentalMonitoringChartType.hourly}
                </span>
              </ToggleButton>
              <ToggleButton
                value={environmentalMonitoringChartType.daily}
                size="small"
                sx={toggleButtonStyle}
              >
                <span className={twCls(textTransform('capitalize'))}>
                  {environmentalMonitoringChartType.daily}
                </span>
              </ToggleButton>
              <ToggleButton
                value={environmentalMonitoringChartType.monthly}
                size="small"
                sx={toggleButtonStyle}
              >
                <span className={twCls(textTransform('capitalize'))}>
                  {environmentalMonitoringChartType.monthly}
                </span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {!isLoadingChartData ? (
            <EnvironmentalMonitorChart
              key={`${isUnit ? unitId : roomId}-${chartType}`}
              getIsCurrentPeriod={chartTypesToGetIsCurrentPeriod[chartType]}
              timeIntervalScaleLabel={chartTypesToTimeIntervalScaleLabelElement[chartType]}
              unitLabel={unitLabel}
              data={chartData}
            />
          ) : (
            <div
              className={twCls(
                height('h-72'),
                display('flex'),
                alignItems('items-center'),
                justifyContent('justify-center'),
              )}
            >
              <Spinner size={75} />
            </div>
          )}
        </>
      ) : detailsReportUnavailable ? (
        <div
          className={twCls(
            display('flex'),
            alignItems('items-center'),
            justifyContent('justify-center'),
          )}
        >
          <p>Sorry, detailed report is currently unavailable</p>
        </div>
      ) : (
        <div
          className={twCls(
            height('h-80'),
            display('flex'),
            alignItems('items-center'),
            justifyContent('justify-center'),
          )}
        >
          <Spinner size={75} />
        </div>
      )}
    </div>
  );
};
