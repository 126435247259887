// ROOMS - Previous Thirty Days - Air Quality Averages

import { baseManagementUrl } from 'services';
import { getPreviousThirtyDaysUTCDateRange, historialEnvironmentalDataApi } from '../sharedUtils';
import {
  EnvMonitoringAllSensorAccumulator,
  HistoricalAirQualityAverages,
  HistoricalEntry,
} from 'models';
import {
  accumulateAllSensorTypesFromHistoricalEntry,
  createAllSensorTypeAccumulatorFromHistoricalEntry,
} from './thirtyDayAvgsSharedUtils';

export const getRoomPreviousThirtyDaysAirQualityAverages = async (
  unitId: string,
): Promise<Map<string, HistoricalAirQualityAverages>> => {
  const roomIdToAllSensorTypesAccumulators = new Map<string, EnvMonitoringAllSensorAccumulator>();

  const { startUtcIsoDate, endUtcIsoDate } = getPreviousThirtyDaysUTCDateRange();

  const perRoomHistoricalEntries: HistoricalEntry[] | undefined =
    await historialEnvironmentalDataApi<HistoricalEntry[]>(
      `${baseManagementUrl}/units/${unitId}/rooms/historicaldata/air-quality/monthly?start_date=${startUtcIsoDate}&end_date=${endUtcIsoDate}`,
    );

  if (!perRoomHistoricalEntries) {
    return new Map<string, HistoricalAirQualityAverages>();
  }

  // Rooms can have multiple sensors.
  // All rooms for the unit specified are returned, so, usually, multiple rooms, and all sensors per room must be averaged together.
  // This code creates a dictionary referencing each room id associated its respective aggregated average values.
  perRoomHistoricalEntries.forEach((entry) => {
    if (!entry?.roomid) {
      // Handles particular case where data was not matched with room information (should not happen moving forward);
      console.error(
        `Unmatched historial air quality record found for thingname: ${entry.thingname}. This record has no associated room id.`,
      );
      return;
    }
    roomIdToAllSensorTypesAccumulators.has(entry.roomid)
      ? roomIdToAllSensorTypesAccumulators.set(
          entry.roomid,
          accumulateAllSensorTypesFromHistoricalEntry(
            entry,
            roomIdToAllSensorTypesAccumulators.get(entry.date),
          ),
        )
      : roomIdToAllSensorTypesAccumulators.set(
          entry.roomid,
          createAllSensorTypeAccumulatorFromHistoricalEntry(entry),
        );
  });

  const finalisedHistoricalAirQualityAverages = finaliseAllSensorTypesAccumulations(
    roomIdToAllSensorTypesAccumulators,
  );

  return finalisedHistoricalAirQualityAverages;
};

const finaliseAllSensorTypesAccumulations = (
  roomIdToAllSensorTypesAccumulators: Map<string, EnvMonitoringAllSensorAccumulator>,
): Map<string, HistoricalAirQualityAverages> => {
  const roomIdToHistoricalAirQualityAverages = new Map<string, HistoricalAirQualityAverages>();
  roomIdToAllSensorTypesAccumulators.forEach((value, key) => {
    roomIdToHistoricalAirQualityAverages.set(key, {
      avg_co2: value.co2.runningTotal / value.co2.runningCount,
      avg_dew: value.dew.runningTotal / value.dew.runningCount,
      avg_rh: value.rh.runningTotal / value.rh.runningCount,
      avg_lux: value.lux.runningTotal / value.lux.runningCount,
      avg_voc: value.voc.runningTotal / value.voc.runningCount,
      avg_temp: value.temp.runningTotal / value.temp.runningCount,
      avg_air_pressure: value.air_pressure.runningTotal / value.air_pressure.runningCount,
      avg_sound_pressure: value.sound_pressure.runningTotal / value.sound_pressure.runningCount,
      avg_pm1: value.pm1.runningTotal / value.pm1.runningCount,
      avg_pm25: value.pm25.runningTotal / value.pm25.runningCount,
      avg_pm4: value.co2.runningTotal / value.co2.runningCount,
      avg_pm10: value.co2.runningTotal / value.co2.runningCount,
    });
  });
  return roomIdToHistoricalAirQualityAverages;
};
