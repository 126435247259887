import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { User } from 'models/user';
import { authFetchBaseQuery, baseUrl } from './apiConfig';
import { ResponseBase } from './request';

export interface SignInResponseSuccess extends ResponseBase {
  token: string;
  refreshToken: string;
}

export interface SignInRequest {
  email: string;
  password: string;
}

export interface SignInError {
  message: string;
  status: string;
  statusCode: number;
}

export interface UserDetailResult {
  avatar: string;
  email: string;
  local_name_space: string;
  name: string;
  status: number;
  unitID: string;
}

export interface UserDetailsResponse extends ResponseBase {
  data: UserDetailResult;
}

export interface SignUpRequest {
  name: string;
  email: string;
  password: string;
  auth_code: string;
}

export interface SignUpError {
  message: string;
  status: string;
  statusCode: number;
}

export interface SignUpResponse {
  avatar: string;
  email: string;
  id: string;
  name: string;
  status: number;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface ResetPasswordResponse {
  message: string;
  status: string;
  statusCode: number;
}

export interface ResetPasswordError {
  message: string;
  status: string;
  statusCode: number;
}

export interface SendVerificationCodeRequest {
  verification_code: string;
  userId: string;
}

export interface SendVerificationCodeError {
  message: string[];
  status: string;
  statusCode: number;
}

export interface ResendVerificationCodeRequest {
  email: string;
  isForgotPassword: boolean;
}

export interface ResendVerificationCodeResponse {
  message: string;
  status: string;
  statusCode: number;
}

export interface ResendVerificationCodeError {
  message: string;
  status: string;
}

export interface SetPasswordRequest {
  email: string;
  password: string;
  confirm_password: string;
  verification_code: string;
}

export interface SetPasswordResponse {
  message: string;
  status: string;
  statusCode: number;
}

export interface SetPasswordError {
  message: string[];
  status: string;
  statusCode: number;
}

const baseEndpointUrl = `${baseUrl}/user`;

export const signInApi = createApi({
  reducerPath: 'signInApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseEndpointUrl,
  }),
  endpoints: (builder) => ({
    signIn: builder.query<SignInResponseSuccess, SignInRequest>({
      query: ({ email, password }) => ({
        url: 'signin',
        method: 'POST',
        body: { email, password },
      }),
      transformErrorResponse: ({ data }) => data as SignInError,
    }),
    signUp: builder.query<SignUpResponse, SignUpRequest>({
      query: (request: SignUpRequest) => ({
        url: 'signup',
        method: 'POST',
        body: request,
      }),
      transformResponse: ({ data }) => data as SignUpResponse,
      transformErrorResponse: ({ data }) => data as SignUpError,
    }),
    resetPassword: builder.query<unknown, ResetPasswordRequest>({
      query: (request: ResetPasswordRequest) => ({
        url: 'forgot/password',
        method: 'POST',
        body: request,
      }),
      transformErrorResponse: ({ data }) => data as ResetPasswordError,
    }),
    sendVerificationCode: builder.query<SendVerificationCodeError, SendVerificationCodeRequest>({
      query: (request: SendVerificationCodeRequest) => ({
        url: 'verify/code',
        method: 'PUT',
        body: request,
      }),
      transformErrorResponse: ({ data }) => data as SendVerificationCodeError,
    }),
    resendVerificationCode: builder.query<
      ResendVerificationCodeResponse,
      ResendVerificationCodeRequest
    >({
      query: (request: ResendVerificationCodeRequest) => ({
        url: 'resend/code',
        method: 'POST',
        body: request,
      }),
      transformResponse: (response) => response as ResendVerificationCodeResponse,
      transformErrorResponse: ({ data }) => data as ResendVerificationCodeError,
    }),
    setPassword: builder.query<unknown, SetPasswordRequest>({
      query: (request: SetPasswordRequest) => ({
        url: 'set/password',
        method: 'PUT',
        body: request,
      }),
      transformErrorResponse: ({ data }) => data as SetPasswordError,
    }),
  }),
});

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: baseEndpointUrl,
  }),
  endpoints: (builder) => ({
    getUserDetails: builder.query<User, void>({
      query: () => ({
        url: 'userDetail',
      }),
      transformResponse: ({ data: userDetails }: UserDetailsResponse) => ({
        avatar: userDetails.avatar,
        email: userDetails.email,
        localNamespace: userDetails.local_name_space,
        name: userDetails.name,
        status: userDetails.status,
        unitId: userDetails.unitID,
      }),
    }),
  }),
});

export const {
  useSignInQuery,
  useLazySignInQuery,
  useSignUpQuery,
  useLazySignUpQuery,
  useLazyResendVerificationCodeQuery,
  useResendVerificationCodeQuery,
  useLazyResetPasswordQuery,
  useResetPasswordQuery,
  useLazySendVerificationCodeQuery,
  useSendVerificationCodeQuery,
  useLazySetPasswordQuery,
  useSetPasswordQuery,
} = signInApi;

export const { useGetUserDetailsQuery, useLazyGetUserDetailsQuery } = usersApi;
