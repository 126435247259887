import { Spinner } from '@mgh-app/component-library';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, isLoading } = useAuth();

  if (isLoading) return <Spinner />;

  return !user ? <Navigate to="/login" replace /> : <>{children}</>;
};
