import { useNavigate } from 'react-router';

export interface UseAppNavigationHook {
  navigateDashboard: () => void;
  navigateLogin: () => void;
}

export const useAppNavigation = (): UseAppNavigationHook => {
  const navigate = useNavigate();

  return {
    navigateDashboard: () => navigate('/dashboard'),
    navigateLogin: () => navigate('/login'),
  };
};
