import { FormControl, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as SmallExpandIcon } from 'assets/smallExpandIcon.svg';
import { useState } from 'react';
import { backgroundColor, borderRadius, twCls } from 'style';

export interface DropdownProps {
  items: string[];
  defaultSelection?: string;
}

export const Dropdown = ({ items, defaultSelection }: DropdownProps): JSX.Element => {
  const [item, setItem] = useState<string>(defaultSelection ?? '');

  const handleChange = (event: SelectChangeEvent) => {
    setItem(event.target.value);
  };

  return (
    <FormControl
      sx={{ minWidth: 200, border: 'none' }}
      size="small"
      className={twCls(backgroundColor('bg-grey-100'), borderRadius('rounded-lg'))}
    >
      <Select
        value={item}
        onChange={handleChange}
        sx={{ fontWeight: 'medium', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
        IconComponent={SmallExpandIcon}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
