import { CircularProgress } from '@mui/material';
import { combineCls, display, justifyContent, margin, twCls } from '@/style';

export interface SpinnerProps {
  size?: number;
}

export const Spinner = ({ size }: SpinnerProps): JSX.Element => {
  return (
    <div
      className={combineCls(
        twCls(display('flex'), justifyContent('justify-center'), margin('mt-4')),
      )}
    >
      <CircularProgress size={size ?? 40} />
    </div>
  );
};
