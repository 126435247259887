import { combineCls, twCls } from '@/style';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import type { DialogProps } from '@mui/material/Dialog';
import { Components } from '@mui/material/styles';
import { backdropClasses } from '@mui/material/Backdrop';
import { dialogTitleClasses } from '@mui/material/DialogTitle';
import { dialogContentClasses } from '@mui/material/DialogContent';
import { dialogActionsClasses } from '@mui/material/DialogActions';

export const dialogTheme: Components['MuiDialog'] = {
  styleOverrides: {
    root: {
      [`& .${backdropClasses.root}`]: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        backdropFilter: 'blur(5px)',
      },
      [`& .${dialogClasses.paper}`]: {
        border: '1px solid rgba(203, 203, 203, 0.5)',
        boxShadow: '0 5px 6px rgb(104 155 224 / 0.65)',
      },
      [`& .${dialogTitleClasses.root}`]: {
        fontSize: '1.125rem',
        color: 'rgb(96, 96, 96)',
        textAlign: 'center',
      },
      [`& .${dialogContentClasses.root}`]: {
        color: 'rgb(47, 47, 47)',
        lineHeight: '1.25rem',
        textAlign: 'center',
      },
      [`& .${dialogActionsClasses.root}`]: {
        display: 'flex',
        justifyContent: 'space-around',
        borderTop: '1px solid rgba(203, 203, 203, 0.5)',
      },
    },
  },
};

export type ModalProps = DialogProps;

export const Modal = (props: ModalProps): JSX.Element => (
  <Dialog className={combineCls(twCls())} {...props} />
);
