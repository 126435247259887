import {
  EnvironmentSensorType,
  getEnvironmentSensorDescription,
  getUnit,
} from '@mgh-app/component-library';
import { AccordionItemExt } from 'components/AccordionItem';

import { environmentalMonitoringDisplayItems } from 'pages/EnvironmentMonitoringPage';
import type { ReactNode } from 'react';
import {
  alignItems,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  gap,
  margin,
  padding,
  twCls,
  width,
} from 'style';
import { isDisplayedSensorType } from 'utils';
import { EnvironmentalMonitorDetails } from '../EnvironmentalMonitorDetails';

export interface HistEnvMonAccordionItemSummaryProps {
  isUnit: boolean;
  unitId: string;
  roomId?: string;
  title: ReactNode;
  type: EnvironmentSensorType;
  showsAverageAndMeaning: boolean;
  trim: number;
  currentValue?: number;
  thirtyDayAverageValue?: number;
  label?: string;
}

export const HistEnvMonAccordionItemSummary = ({
  isUnit,
  unitId,
  roomId,
  title,
  type,
  showsAverageAndMeaning,
  trim,
  currentValue,
  thirtyDayAverageValue,
  label,
}: HistEnvMonAccordionItemSummaryProps): JSX.Element => {
  return (
    <>
      {isDisplayedSensorType(type) ? (
        <AccordionItemExt
          summary={
            <div
              className={twCls(
                display('flex'),
                flexDirection('flex-col', 'md:flex-row'),
                gap('gap-4'),
              )}
            >
              <div className={twCls(width('w-56'))}>
                <div className={twCls(display('flex'))}>
                  {environmentalMonitoringDisplayItems[type].icon}
                  <div
                    className={twCls(
                      display('flex'),
                      alignItems('items-center'),
                      margin('ml-3'),
                      fontSize('text-xl'),
                    )}
                  >
                    {title}
                  </div>
                </div>
                {(currentValue !== undefined && !Number.isNaN(currentValue)) ||
                (showsAverageAndMeaning &&
                  thirtyDayAverageValue !== undefined &&
                  !Number.isNaN(thirtyDayAverageValue)) ? (
                  <div
                    className={twCls(display('flex'), flexDirection('flex-col'), padding('pt-4'))}
                  >
                    {currentValue !== undefined && !Number.isNaN(currentValue) ? (
                      <div
                        className={twCls(
                          display('flex'),
                          flexDirection('flex-col'),
                          padding('pt-4'),
                        )}
                      >
                        <span className={twCls(fontSize('text-sm'))}>Current Value</span>
                        <span className={twCls(fontWeight('font-medium'))}>
                          <span className={twCls(fontSize('text-3xl'))}>{currentValue}</span>{' '}
                          {getUnit(type)}
                        </span>
                      </div>
                    ) : null}
                    {showsAverageAndMeaning &&
                    thirtyDayAverageValue !== undefined &&
                    !Number.isNaN(thirtyDayAverageValue) ? (
                      <div
                        className={twCls(
                          display('flex'),
                          flexDirection('flex-col'),
                          padding('pt-4'),
                        )}
                      >
                        <h4 className={twCls(fontSize('text-sm'))}>30 Day Average</h4>
                        <p className={twCls(fontWeight('font-medium'))}>
                          <span className={twCls(fontSize('text-3xl'))}>
                            {thirtyDayAverageValue.toFixed(trim)}
                          </span>{' '}
                          {getUnit(type)}
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div
                className={twCls(
                  display('flex'),
                  flexDirection('flex-col'),
                  margin('mt-6', 'md:mt-0'),
                )}
              >
                {label && (!isUnit || showsAverageAndMeaning) && (
                  <>
                    <span className={twCls(fontSize('text-xl'))}>
                      What This Result Means To You
                    </span>
                    <span className={twCls(margin('mt-2'))}>
                      {getEnvironmentSensorDescription(type, label)}
                    </span>
                  </>
                )}
              </div>
            </div>
          }
          details={(expanded) => (
            <EnvironmentalMonitorDetails
              isUnit={isUnit}
              expanded={expanded}
              unitLabel={getUnit(type)}
              unitId={unitId}
              roomId={roomId}
              type={type}
            />
          )}
        />
      ) : null}
    </>
  );
};
