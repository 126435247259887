import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  padding,
  textColor,
  twCls,
} from 'style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = ({ active, payload }: any) => {
  if (active) {
    return (
      <div
        className={twCls(
          backgroundColor('bg-white'),
          borderWidth('border'),
          borderColor('border-grey-400'),
          borderRadius('rounded-lg'),
          padding('px-2', 'py-1'),
        )}
      >
        <p className={twCls(textColor('text-blue-100'))}>
          {payload[0]?.value}
          {payload[0]?.unit}
        </p>
      </div>
    );
  }

  return null;
};

export interface EnergyChartData {
  measureType: string;
  value: number;
}

export interface EnergyChartProps {
  chartData: EnergyChartData[];
}

export const EnergyChart = ({ chartData }: EnergyChartProps): JSX.Element => {
  const [yAxisWidth, setYAxisWidth] = useState<number>(130);
  const [yAxisTickFontSize, setYAxisTickFontSize] = useState<number>(16);

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setYAxisWidth(114);
      setYAxisTickFontSize(14);
    } else {
      setYAxisWidth(130);
      setYAxisTickFontSize(16);
    }

    const handleResize = () => {
      // sm: 640px
      // md: 768px
      // lg: 1024px
      // xl: 1280px
      // 2xl: 1536px
      if (window.innerWidth <= 640) {
        setYAxisWidth(114);
        setYAxisTickFontSize(14);
      } else {
        setYAxisWidth(130);
        setYAxisTickFontSize(16);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ResponsiveContainer height={120}>
      <BarChart layout="vertical" data={chartData}>
        <CartesianGrid vertical={true} horizontal={false} />
        <XAxis type="number" tickLine={false} />
        <YAxis
          type="category"
          width={yAxisWidth}
          dataKey="measureType"
          tickLine={false}
          tick={{
            fontWeight: 500,
            fill: 'black',
            fontSize: yAxisTickFontSize,
          }}
          label={{ value: 'kWh', position: 'bottom', offset: 8 }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" unit={' kWh'} fill="#118dff" barSize={17} radius={[0, 5, 5, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};
