import { useRoutes, Navigate } from 'react-router-dom';
import { ProtectedRoute } from 'auth/ProtectedRoute';
import { MainLayout } from './layouts/MainLayout';
import { DashboardPage } from 'pages/DashboardPage';
import { LoginPage } from 'pages/LoginPage';
import { MyEnergyPage } from 'pages/MyEnergyPage';
import { EnvironmentMonitoringPage } from 'pages/EnvironmentMonitoringPage';
import { UltraWebAppPage } from 'pages/UltraWebAppPage';
import { MyAccountPage } from 'pages/MyAccountPage';

const Router = () => {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Navigate to="/dashboard" />
            </ProtectedRoute>
          ),
        },
        {
          path: '/dashboard',
          element: (
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          ),
        },
        {
          path: '/my-home',
          element: (
            <ProtectedRoute>
              <Navigate to="/my-home/my-energy" />
            </ProtectedRoute>
          ),
        },
        {
          path: '/my-home/my-energy',
          element: (
            <ProtectedRoute>
              <MyEnergyPage />
            </ProtectedRoute>
          ),
        },
        {
          path: '/my-home/environmental-monitoring',
          element: (
            <ProtectedRoute>
              <EnvironmentMonitoringPage />
            </ProtectedRoute>
          ),
        },
        {
          path: '/ultra-web-app',
          element: (
            <ProtectedRoute>
              <UltraWebAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: '/my-account',
          element: (
            <ProtectedRoute>
              <MyAccountPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
  ]);

  return routes;
};

export default Router;
