import {
  twCls,
  padding,
  margin,
  display,
  justifyContent,
  width,
  position,
  inset,
  textColor,
  alignItems,
  height,
  TTailwindString,
} from '@/style';

import {
  CombinedShape,
  CombinedShapeWarning,
  CombinedShapeDanger,
  Co2,
  Compound,
  Dew,
  Lux,
  Humidity,
  Particulates,
  Temperature,
  AirPressure,
  SoundPressure,
} from '../Icons';

export enum EnvironmentSensorType {
  Unknown = 'unknown',
  CO2 = 'co2',
  VOC = 'voc',
  Lux = 'lux',
  Dew = 'dew',
  RH = 'rh',
  Temperature = 'temp',
  AirPressure = 'air_pressure',
  SoundPressure = 'sound_pressure',
  PM1 = 'pm1',
  PM25 = 'pm25',
  PM4 = 'pm4',
  PM10 = 'pm10',
  Particulates = 'particulates',
}

const getCombinedShape = (type: EnvironmentSensorType, label: string | undefined) => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      switch (label) {
        case 'GOOD':
          return <CombinedShape />;
        case 'POOR':
          return <CombinedShapeWarning />;
        case 'WARNING':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.VOC:
      switch (label) {
        case 'LOW':
        case 'ACCEPTABLE':
          return <CombinedShape />;
        case 'MARGINAL':
          return <CombinedShapeWarning />;
        case 'HIGH':
        case 'VERY HIGH':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.RH:
      switch (label) {
        case 'DRY':
          return <CombinedShapeWarning />;
        case 'IDEAL':
          return <CombinedShape />;
        case 'HIGH':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.Lux:
      switch (label) {
        case 'DIM':
        case 'LOW':
          return <CombinedShapeWarning />;
        case 'IDEAL':
          return <CombinedShape />;
        case 'BRIGHT':
        case 'HIGH':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.Dew:
      switch (label) {
        case 'IDEAL':
          return <CombinedShape />;
        case 'LOW':
        case 'HIGH':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.Temperature:
      switch (label) {
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.AirPressure:
      switch (label) {
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.PM25:
      switch (label) {
        case 'GOOD':
          return <CombinedShape />;
        case 'MODERATE':
          return <CombinedShapeWarning />;
        case 'HIGH':
        case 'VERY HIGH':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    case EnvironmentSensorType.PM10:
      switch (label) {
        case 'GOOD':
          return <CombinedShape />;
        case 'MODERATE':
          return <CombinedShapeWarning />;
        case 'HIGH':
        case 'VERY HIGH':
          return <CombinedShapeDanger />;
        default:
          return <CombinedShape />;
      }
    default:
      return <CombinedShape />;
  }
};

export const getLabelColor = (
  type: EnvironmentSensorType,
  label: string | undefined,
): TTailwindString => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      switch (label) {
        case 'GOOD':
          return twCls(textColor('text-green'));
        case 'POOR':
          return twCls(textColor('text-amber'));
        case 'WARNING':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.VOC:
      switch (label) {
        case 'LOW':
        case 'ACCEPTABLE':
          return twCls(textColor('text-green'));
        case 'MARGINAL':
          return twCls(textColor('text-amber'));
        case 'HIGH':
        case 'VERY HIGH':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.RH:
      switch (label) {
        case 'DRY':
          return twCls(textColor('text-amber'));
        case 'IDEAL':
          return twCls(textColor('text-green'));
        case 'HIGH':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.Lux:
      switch (label) {
        case 'DIM':
        case 'LOW':
          return twCls(textColor('text-amber'));
        case 'IDEAL':
          return twCls(textColor('text-green'));
        case 'BRIGHT':
        case 'HIGH':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.Dew:
      switch (label) {
        case 'IDEAL':
          return twCls(textColor('text-green'));
        case 'LOW':
        case 'HIGH':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.Temperature:
      switch (label) {
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.AirPressure:
      switch (label) {
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.PM25:
      switch (label) {
        case 'GOOD':
          return twCls(textColor('text-green'));
        case 'MODERATE':
          return twCls(textColor('text-amber'));
        case 'HIGH':
        case 'VERY HIGH':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    case EnvironmentSensorType.PM10:
      switch (label) {
        case 'GOOD':
          return twCls(textColor('text-green'));
        case 'MODERATE':
          return twCls(textColor('text-amber'));
        case 'HIGH':
        case 'VERY HIGH':
          return twCls(textColor('text-red-200'));
        default:
          return twCls(textColor('text-green'));
      }
    default:
      return twCls(textColor('text-green'));
  }
};

const getIcon = (type: EnvironmentSensorType) => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      return <Co2 />;
    case EnvironmentSensorType.VOC:
      return <Compound />;
    case EnvironmentSensorType.Dew:
      return <Dew />;
    case EnvironmentSensorType.Lux:
      return <Lux />;
    case EnvironmentSensorType.RH:
      return <Humidity />;
    case EnvironmentSensorType.Temperature:
      return <Temperature />;
    case EnvironmentSensorType.AirPressure:
      return <AirPressure />;
    case EnvironmentSensorType.SoundPressure:
      return <SoundPressure />;
    case EnvironmentSensorType.PM1:
    case EnvironmentSensorType.PM25:
    case EnvironmentSensorType.PM4:
    case EnvironmentSensorType.PM10:
      return <Particulates />;
    default:
      throw new Error('Unknown sensor type');
  }
};

export const getUnit = (type: EnvironmentSensorType): string => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      return 'ppm';
    case EnvironmentSensorType.VOC:
      return 'ppb';
    case EnvironmentSensorType.Dew:
      return '°C';
    case EnvironmentSensorType.Lux:
      return 'lx';
    case EnvironmentSensorType.RH:
      return '%';
    case EnvironmentSensorType.Temperature:
      return '°C';
    case EnvironmentSensorType.AirPressure:
      return 'kPa';
    case EnvironmentSensorType.SoundPressure:
      return 'dB';
    case EnvironmentSensorType.PM1:
    case EnvironmentSensorType.PM25:
    case EnvironmentSensorType.PM4:
    case EnvironmentSensorType.PM10:
      return 'µg/m³';
    default:
      throw new Error('Unknown sensor type');
  }
};

export const getEnvironmentSensorDescription = (
  type: EnvironmentSensorType,
  label: string | undefined,
): string => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      switch (label) {
        case 'GOOD':
          return 'Well within indoor safe ranges with good air exchange';
        case 'POOR':
          return 'Stagnant, stale, stuffy air associated with headaches, drowsiness, slight nausea';
        case 'WARNING':
          return 'Indicates unusually high levels and toxicity or oxygen deprivation could occur';
        default:
          return '<Unknown level>';
      }
    case EnvironmentSensorType.VOC:
      switch (label) {
        case 'LOW':
          return 'VOC well beneath acceptable levels, low concern to health';
        case 'ACCEPTABLE':
          return 'VOC levels just within recommended indoor guidelines';
        case 'MARGINAL':
          return 'Some cause for concern with potential for eye, nose and throat irritation';
        case 'HIGH':
          return 'Potentially harmful with risk of aggravation to existing respiratory diseases';
        case 'VERY HIGH':
          return 'Not acceptable';
        default:
          return '<Unknown level>';
      }
    case EnvironmentSensorType.RH:
      switch (label) {
        case 'DRY':
          return "Low humidity inhibits our body's natural defence";
        case 'IDEAL':
          return 'Ideal humidity, airborne bacteria and viruses struggle to survive';
        case 'HIGH':
          return 'Bacteria, viruses, germs and mould thrive';
        default:
          return '<Unknown level>';
      }
    case EnvironmentSensorType.Lux:
      switch (label) {
        case 'DIM':
        case 'LOW':
          return 'Lighting level is ideal for relaxing but not for reading or tasks';
        case 'IDEAL':
          return 'Lighting level is in the ideal range for a range of tasks';
        case 'BRIGHT':
        case 'HIGH':
          return 'Lighting level is very bright';
        default:
          return '<Unknown level>';
      }
    case EnvironmentSensorType.Dew:
      switch (label) {
        case 'LOW':
          return 'Dew point is low, discomfort is likely';
        case 'IDEAL':
          return 'Dew point is within recommended thresholds';
        case 'HIGH':
          return 'Dew point is high, condensation is likely';
        default:
          return '<Unknown level>';
      }
    case EnvironmentSensorType.Temperature:
      switch (label) {
        default:
          return 'Current temperature in the room';
      }
    case EnvironmentSensorType.AirPressure:
      switch (label) {
        default:
          return 'Atmospheric pressure as an indication of weather. When a low pressure system moves into an area, it usually leads to cloudiness, wind and precipitation.';
      }
    case EnvironmentSensorType.PM25:
      switch (label) {
        case 'GOOD':
          return 'WHO AQG 24 hours';
        case 'MODERATE':
          return 'Interim target 4';
        case 'HIGH':
          return 'Interim target 3';
        case 'VERY HIGH':
          return 'Interim target 2';
        default:
          return '<Unknown level>';
      }
    case EnvironmentSensorType.PM10:
      switch (label) {
        case 'GOOD':
          return 'WHO AQG 24 hours';
        case 'MODERATE':
          return 'Interim target 4';
        case 'HIGH':
          return 'Interim target 3';
        case 'VERY HIGH':
          return 'Interim target 2';
        default:
          return '<Unknown level>';
      }
    default:
      return '<Unknown sensor type>';
  }
};

export interface EnvironmentMonitoringItemProps {
  type: EnvironmentSensorType;
  value: number | undefined;
  label: string | undefined;
}

export const EnvironmentMonitoringItem = ({
  type,
  value,
  label,
}: EnvironmentMonitoringItemProps): JSX.Element => {
  return (
    <div>
      <div
        className={twCls(
          position('relative'),
          display('flex'),
          alignItems('items-center'),
          padding('pt-0.5'),
        )}
      >
        {getCombinedShape(type, label)}
        <div
          className={twCls(
            position('absolute'),
            inset('top-0', 'left-0'),
            display('flex'),
            alignItems('items-center'),
          )}
        >
          <div
            className={twCls(
              width('w-16'),
              height('h-16'),
              padding('pb-1'),
              display('flex'),
              alignItems('items-center'),
              justifyContent('justify-center'),
            )}
          >
            <div className={twCls(margin('ml-2', 'mt-1.5'))}>{getIcon(type)}</div>
          </div>
          <div
            className={twCls(
              textColor('text-white'),
              display('flex'),
              alignItems('items-center'),
              justifyContent('justify-center'),
              margin('mt-0.5'),
            )}
          >
            {value != undefined ? `${value} ${getUnit(type)}` : 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );
};
