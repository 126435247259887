import { ButtonProps as MuiButtonProps, default as MuiButton } from '@mui/material/Button';
import { backgroundColor, borderRadius, combineCls, textTransform, twCls } from '@/style';

export type ButtonProps = MuiButtonProps;

export const Button = ({ children, className, ...rest }: ButtonProps): JSX.Element => {
  return (
    <MuiButton
      variant="contained"
      {...rest}
      className={combineCls(
        twCls(
          backgroundColor('bg-blue-100'),
          textTransform('normal-case'),
          borderRadius('rounded-lg'),
        ),
        className,
      )}
    >
      {children}
    </MuiButton>
  );
};
