import { EnvironmentSensorType } from '@mgh-app/component-library';
import {
  EnvMonitoringAggregatesAccumulator,
  EnvironmentalMonitorChartDatum,
  HistoricalEntry,
} from 'models';
import {
  accumulateSingleSensorTypeFromHistoricalEntry,
  getPreviousThirtyDaysUTCDateRange,
  historialEnvironmentalDataApi,
  sortIsoDateKeyedTupleArray,
} from '../sharedUtils';
import { baseManagementUrl } from 'services';
import { DateTime } from 'luxon';
import {
  getChartDataForEachMissingUtcIsoDatesDaily,
  getSetOfExpectedUtcIsoDateDaily,
} from './dailySharedUtilities';

// ROOM - Daily - Env Mon Chart Data

// The 'extensible' in name is in anticipation of a backend change being made to return a running total
// value along with a running count for each aggregate value returned, making them 'extensible' in
// so far as they can be averaged together into groups as necessary. Example: here, multiple
// entries for a specific room for a specific date can be returned as there can be multiple sensors in a room
// by maintaining a running count and a running total this allows for computation of the average of the
// multiple relevant entries together to produce the accurate average for the room-date group.

export const getRoomDailyExtensibleAggregateDataForEnvMonChart = async (
  unitId: string,
  type: EnvironmentSensorType,
  roomId?: string,
): Promise<EnvironmentalMonitorChartDatum[] | null> => {
  if (!roomId) {
    return null;
  }
  const roomIdToHistoricalAirQualityAverages = new Map<
    string,
    EnvMonitoringAggregatesAccumulator
  >();

  // Get the start and end date for the search.
  const { startUtcIsoDate, endUtcIsoDate } = getPreviousThirtyDaysUTCDateRange(false);

  const perRoomHistoricalEntries: HistoricalEntry[] | undefined =
    await historialEnvironmentalDataApi<HistoricalEntry[]>(
      `${baseManagementUrl}/units/${unitId}/rooms/historicaldata/air-quality/daily?start_date=${startUtcIsoDate}&end_date=${endUtcIsoDate}`,
    );

  if (!perRoomHistoricalEntries) {
    return null;
  }

  // Populate set containing all utcIsoDates expected in range.
  const setOfExpectedUtcIsoDates = getSetOfExpectedUtcIsoDateDaily(
    startUtcIsoDate,
    endUtcIsoDate,
  );

  // Group the entries for the specified room by day, accumulating, respectively.
  perRoomHistoricalEntries.forEach((entry) => {
    if (entry.roomid !== roomId) {
      return;
    }

    // Remove each utcIsoDate from expected set for the given range for each utcIsoDate encountered in the received data.
    const isFirstEncounter = setOfExpectedUtcIsoDates.delete(entry.date);

    !isFirstEncounter // Group and prepare data for aggregation of averages, per date.
      ? // If an entry for the current date is found, update it from the current entry.
        roomIdToHistoricalAirQualityAverages.set(
          entry.date,
          accumulateSingleSensorTypeFromHistoricalEntry(
            entry,
            type,
            roomIdToHistoricalAirQualityAverages.get(entry.date),
          ),
        )
      : // If an entry for the current date is not found, create a new accumulator from the current entry.
        roomIdToHistoricalAirQualityAverages.set(
          entry.date,
          accumulateSingleSensorTypeFromHistoricalEntry(entry, type),
        );
  });

  // Complete calculation of average aggregate
  const unsortedSortData: (readonly [string, EnvironmentalMonitorChartDatum])[] =
    // Add in missing data chart data.
    getChartDataForEachMissingUtcIsoDatesDaily(setOfExpectedUtcIsoDates);
  roomIdToHistoricalAirQualityAverages.forEach((accumulator, currentUtcIsoDate) => {
    let chartDatum = convertAccumToDailyLocalTimeLabelledChartDatum(accumulator, currentUtcIsoDate);
    if (currentUtcIsoDate === DateTime.utc().toISODate()) {
      chartDatum = { ...chartDatum, avg: undefined };
    }

    // Perform average calculation for accumulator and convert to a list of date string to evn monitoring datum tuple form to allow for sorting to take place.
    unsortedSortData.push([currentUtcIsoDate, chartDatum] as const);
  });

  const sortedSortData = sortIsoDateKeyedTupleArray(unsortedSortData);

  const sortedChartData: EnvironmentalMonitorChartDatum[] = sortedSortData.map(
    ([, datum]) => datum,
  );

  return sortedChartData;
};

const convertAccumToDailyLocalTimeLabelledChartDatum = (
  accumulator: EnvMonitoringAggregatesAccumulator,
  utcIsoDate: string,
): EnvironmentalMonitorChartDatum => {
  const chartDatum: EnvironmentalMonitorChartDatum = {
    timeIntervalLabel: DateTime.fromISO(utcIsoDate).toLocal().day,
    avg: accumulator.runningTotal / accumulator.runningCount,
    minMax: [accumulator.runningMin, accumulator.runningMax],
  };
  return chartDatum;
};
