import { EnvironmentalMonitorChartDatum } from 'models';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  alignItems,
  display,
  fontSize,
  fontWeight,
  height,
  inset,
  justifyContent,
  lineHeight,
  padding,
  position,
  textColor,
  twCls,
} from 'style';
import type { Complete } from 'types';

export interface EnvironmentalMonitorChartProps {
  unitLabel: string;
  data: EnvironmentalMonitorChartDatum[];
  timeIntervalScaleLabel: JSX.Element;
  getIsCurrentPeriod: (
    timeIntervalLabel: string | number,
    dataLength: number,
    index: number,
  ) => boolean;
}

// Prevents stringly typed api errors, where any property name changes in datum will produce
// a typescript error here, rather than silently breaking all the charts, which are dependent
// on the string match between the property name and the dataKey string.
const chartDatumDataKeys: { [Key in keyof Complete<EnvironmentalMonitorChartDatum>]: Key } = {
  timeIntervalLabel: 'timeIntervalLabel',
  avg: 'avg',
  minMax: 'minMax',
};

export const EnvironmentalMonitorChart = ({
  unitLabel,
  data,
  timeIntervalScaleLabel,
  getIsCurrentPeriod,
}: EnvironmentalMonitorChartProps): JSX.Element => {
  return (
    <div className={twCls(position('relative'), height('h-fit'), textColor('text-grey-950'))}>
      <ResponsiveContainer height={300} width="95%">
        <ComposedChart
          width={730}
          height={250}
          data={data}
          margin={{ left: 0, top: 50, right: 0, bottom: 20 }}
          barSize={12}
        >
          <XAxis
            dataKey={chartDatumDataKeys.timeIntervalLabel}
            tickSize={0}
            tickMargin={27}
            type="category"
            tick={{ fill: '#363636' }}
            fontSize={14}
          />
          <YAxis
            axisLine={false}
            tickSize={0}
            tickMargin={20}
            fontWeight={500}
            tick={{ fill: '#363636' }}
            width={70}
          />
          <CartesianGrid vertical={false} horizontal={true} />
          <Bar dataKey={chartDatumDataKeys.minMax} radius={[6, 6, 6, 6]}>
            {data.map((datum, index) => (
              <Cell
                key={`cell-${datum.timeIntervalLabel}`}
                fill={
                  getIsCurrentPeriod(datum.timeIntervalLabel, data.length, index)
                    ? '#c4c4c4'
                    : '#118dff'
                }
              />
            ))}
          </Bar>
          <Line
            type="monotone"
            dataKey={chartDatumDataKeys.avg}
            stroke="none"
            dot={{ fill: 'black', strokeWidth: 2, r: 6 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <p
        className={twCls(
          position('absolute'),
          inset('top-0'),
          fontWeight('font-semibold'),
          fontSize('text-lg'),
        )}
      >
        {unitLabel}
      </p>
      {timeIntervalScaleLabel}
      <div
        className={twCls(
          display('flex'),
          alignItems('items-center'),
          justifyContent('justify-center'),
        )}
      >
        <p className={twCls(fontSize('text-4xl'), lineHeight('leading-4'), padding('pr-1'))}>•</p>
        <p className={twCls(fontSize('text-sm'))}>Average</p>
      </div>
    </div>
  );
};
