import { DateTime } from 'luxon';
import { MouseEvent, useState } from 'react';

import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  fontWeight,
  height,
  justifyContent,
  margin,
  padding,
  textColor,
  twCls,
  width,
} from 'style';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const toggleButtonStyle = {
  '&.MuiToggleButton-root': {
    color: 'black',
    backgroundColor: '#e9f0f5',
    border: 'solid 2px #e9f0f5',
    borderRadius: '8px',
    paddingX: '1.5rem',
    textTransform: 'none',
    fontSize: '1rem',
  },
  '&.Mui-selected': {
    backgroundColor: 'white',
    fontWeight: 'bold',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = ({ active, payload }: any) => {
  if (active) {
    return (
      <div
        className={twCls(
          backgroundColor('bg-white'),
          borderWidth('border'),
          borderColor('border-grey-400'),
          borderRadius('rounded-lg'),
          padding('px-2', 'py-1'),
        )}
      >
        <p className={twCls(textColor('text-blue-100'))}>
          {payload[0]?.value}
          {payload[0]?.unit}
        </p>
        <p>
          {payload[1]?.value}
          {payload[1]?.unit}
        </p>
      </div>
    );
  }

  return null;
};

export interface HistoricalEnergyData {
  index: number;
  indexName: string;
  date: DateTime;
  value: number;
  temperature?: number;
}

export interface EnergyUseChartProps {
  data: HistoricalEnergyData[];
  xAxisLabel: string;
  showTemperature: boolean;
}

export const EnergyUseChart = ({
  data,
  xAxisLabel,
  showTemperature,
}: EnergyUseChartProps): JSX.Element => {
  const calculateDomain = (maxValue: number) => {
    if (maxValue < 0.001) {
      return Math.ceil(maxValue) / 1000;
    }

    if (maxValue < 0.01) {
      return Math.ceil(maxValue) / 100;
    }

    if (maxValue < 0.1) {
      return Math.ceil(maxValue) / 10;
    }

    if (maxValue >= 10 && maxValue < 100) {
      return Math.ceil((maxValue + 1) / 10) * 10;
    }

    if (maxValue >= 100 && maxValue < 1000) {
      return Math.ceil((maxValue + 1) / 100) * 100;
    }

    if (maxValue >= 1000 && maxValue < 10000) {
      return Math.ceil((maxValue + 1) / 1000) * 1000;
    }

    return Math.ceil(maxValue * 1.25);
  };

  return (
    <ResponsiveContainer height={400} width="95%">
      <ComposedChart data={data} margin={{ left: 30, top: 30, right: 0, bottom: 30 }}>
        <CartesianGrid vertical={false} />
        <CartesianGrid vertical={false} strokeDasharray={3} />
        <XAxis dataKey="indexName" axisLine={false} tickLine={false} tickMargin={8} />
        <YAxis
          yAxisId="energy"
          domain={[0, (dataMax: number) => calculateDomain(dataMax)]}
          axisLine={false}
          tickLine={false}
          label={{ value: 'kWh', position: 'top', offset: 18 }}
        />
        {showTemperature && (
          <YAxis orientation="right" unit=" °C" axisLine={false} tickLine={false} />
        )}
        <Tooltip content={<CustomTooltip />} />
        <Bar
          yAxisId="energy"
          dataKey="value"
          fill="#118dff"
          unit={' kWh'}
          radius={[100, 100, 0, 0]}
          barSize={10}
        />
        {showTemperature && (
          <Line
            dataKey="temperature"
            unit={'°C'}
            type="linear"
            dot={false}
            strokeWidth={3}
            stroke="#0e4475"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export interface EnergyUseProps {
  weekData: HistoricalEnergyData[];
  yearData: HistoricalEnergyData[];
}

export const EnergyUse = ({ weekData, yearData }: EnergyUseProps): JSX.Element => {
  const [filter, setFilter] = useState<string>('Week');

  const showTemperature = () => {
    switch (filter) {
      case 'Week':
        return weekData.every((v) => v.hasOwnProperty('temperature'));
      case 'Year':
        return yearData.every((v) => v.hasOwnProperty('temperature'));
      default:
        return false;
    }
  };

  const handleFilter = (_: MouseEvent<HTMLElement>, newFilter: string | null) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  return (
    <div>
      <div
        className={twCls(display('flex'), justifyContent('justify-end'), padding('p-2', 'md:p-8'))}
      >
        <ToggleButtonGroup value={filter} exclusive onChange={handleFilter}>
          <ToggleButton value="Week" size="small" sx={toggleButtonStyle}>
            Week
          </ToggleButton>
          <ToggleButton value="Year" size="small" sx={toggleButtonStyle}>
            Year
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {showTemperature() && (
        <div className={twCls(display('flex'), justifyContent('justify-end'), padding('pr-8'))}>
          <hr
            className={twCls(
              width('w-12'),
              height('h-1'),
              backgroundColor('bg-blue-900'),
              margin('mt-2.5', 'mr-3'),
            )}
          />
          <span className={twCls(fontWeight('font-medium'))}>Temperature</span>
        </div>
      )}

      {filter === 'Week' && (
        <EnergyUseChart data={weekData} xAxisLabel="DAY" showTemperature={showTemperature()} />
      )}
      {filter === 'Year' && (
        <EnergyUseChart data={yearData} xAxisLabel="MONTH" showTemperature={showTemperature()} />
      )}
    </div>
  );
};
