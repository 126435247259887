import { IonDatetime, setupIonicReact } from '@ionic/react';
import { forwardRef, Ref } from 'react';

setupIonicReact();

export type TimePickerProps = React.ComponentProps<typeof IonDatetime>;

const TimePickerInternal = (props: TimePickerProps, ref: Ref<HTMLIonDatetimeElement>) => (
  <IonDatetime id="datetime" presentation="time" ref={ref} {...props}></IonDatetime>
);

export const TimePicker = forwardRef<HTMLIonDatetimeElement>(TimePickerInternal);
