import { EnvironmentSensorType } from '@mgh-app/component-library';
import { DateTime } from 'luxon';
import { HistoricalEntry, EnvironmentalMonitorChartDatum, SensorAggregates } from 'models';
import { baseManagementUrl } from 'services';
import {
  getPreviousThirtyDaysUTCDateRange,
  historialEnvironmentalDataApi,
  sortIsoDateKeyedTupleArray as sortIsoDateKeyedTupleArray,
} from '../sharedUtils';
import {
  getChartDataForEachMissingUtcIsoDatesDaily,
  getSetOfExpectedUtcIsoDateDaily,
} from './dailySharedUtilities';

// UNIT - Daily - Env Mon Chart Data
// TODO: Handle yet-to-implement changes to backend to work with extensible aggregation data
// (e.g. by including: runningSum and runningCount data along with per date per room data
// this is important so as to support calculation of averages with additional data points, such as, here,
// where additional sensor data from the same room, sensor type, and date, must be averaged together to produce the true average for the day period per room;
// rather than just a pre-calculated average per sensor entity per room per date as we have already: avg).

// The Name change here reflects support for this future change as well:
export const getUnitDailyExtensibleAggregationDataForEnvMonChart = async (
  unitId: string,
  type: EnvironmentSensorType,
) => {
  // Get the start and end date for the search.
  const { startUtcIsoDate, endUtcIsoDate } = getPreviousThirtyDaysUTCDateRange(false);

  const unitHistoricalEntries: HistoricalEntry[] | undefined = await historialEnvironmentalDataApi<
    HistoricalEntry[]
  >(
    `${baseManagementUrl}/units/${unitId}/historicaldata/air-quality/daily?start_date=${startUtcIsoDate}&end_date=${endUtcIsoDate}`,
  );

  if (!unitHistoricalEntries) {
    return null;
  }
  // Populate set containing all utcIsoDates expected in range.
  const setOfExpectedUtcIsoDates = getSetOfExpectedUtcIsoDateDaily(
    startUtcIsoDate,
    endUtcIsoDate,
  );

  let unsortedSortData: (readonly [
    localUtcDateTime: string,
    datum: EnvironmentalMonitorChartDatum,
  ])[] = [];
  unitHistoricalEntries.forEach(({ date: currentUtcIsoDate, ...restEntry }) => {
    let chartDatum = convertHistoricalEntryToDailyEnvironmentalMonitorChartDatum(
      { date: currentUtcIsoDate, ...restEntry },
      type,
    );
    // The current day average is incomplete, so set it to undefined to indicate this, and ensure a value is not displayed in the chart.
    if (currentUtcIsoDate === DateTime.utc().toISODate()) {
      chartDatum = { ...chartDatum, avg: undefined };
    }

    // Remove each utcIsoDate from expected set for the given range for each utcIsoDate encountered in the received data.
    setOfExpectedUtcIsoDates.delete(currentUtcIsoDate);

    unsortedSortData.push([currentUtcIsoDate, chartDatum] as const);
  });

  // Add in missing data chart data.
  unsortedSortData = [
    ...unsortedSortData,
    ...getChartDataForEachMissingUtcIsoDatesDaily(setOfExpectedUtcIsoDates),
  ];

  const sortedSortData = sortIsoDateKeyedTupleArray(unsortedSortData);

  const sortedChartData: EnvironmentalMonitorChartDatum[] = sortedSortData.map(
    (sortDatum) => sortDatum[1],
  );
  return sortedChartData;
};

const convertHistoricalEntryToDailyEnvironmentalMonitorChartDatum = (
  entry: HistoricalEntry,
  type: EnvironmentSensorType,
): EnvironmentalMonitorChartDatum => {
  const fullEntry = new Map<string, SensorAggregates>(Object.entries(entry));
  const currentSensorTypePartial = fullEntry.get(type);
  if (!currentSensorTypePartial) {
    throw Error('Historical entry conversion attempted on unsupported environmental sensor type');
  }

  const environmentalMonitorChartDatum: EnvironmentalMonitorChartDatum = {
    timeIntervalLabel: DateTime.fromISO(entry.date).toLocal().day,
    avg: currentSensorTypePartial.avg,
    minMax: [currentSensorTypePartial.min, currentSensorTypePartial.max],
  };

  return environmentalMonitorChartDatum;
};
