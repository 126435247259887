import { getTokensFromLocalStorage } from './apiConfig';

export interface ResponseBase {
  statusCode: number;
  status: string;
  message: string | string[];
}

export interface ErrorResponse<TErrorData> extends ResponseBase {
  data: TErrorData;
}

export class ApiError<TError> extends Error {
  response?: ErrorResponse<TError>;

  constructor(message?: string, response?: ErrorResponse<TError>) {
    super(`API Error: ${message}`);
    this.response = response;
  }
}

export async function request<TResponse extends ResponseBase, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> {
  const response = await fetch(url, config);
  const data = await response.json();

  if (response.status >= 400) {
    throw new ApiError(response.statusText, data as ErrorResponse<TError>);
  }

  return data as TResponse;
}

export const authRequest = <TResponse extends ResponseBase, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> => {
  const { token } = getTokensFromLocalStorage();

  return request<TResponse, TError>(url, {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  });
};

export const get = <TResponse extends ResponseBase, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> =>
  authRequest<TResponse, TError>(url, {
    method: 'GET',
    ...config,
    headers: {
      'Content-Type': 'application/json',
      ...config.headers,
    },
  });

export const post = <TResponse extends ResponseBase, TRequest, TError = unknown>(
  url: string,
  body: TRequest,
  config: RequestInit = {},
): Promise<TResponse> =>
  authRequest<TResponse, TError>(url, {
    method: 'POST',
    body: body ? JSON.stringify(body) : '',
    ...config,
    headers: {
      'Content-Type': 'application/json',
      ...config.headers,
    },
  });
