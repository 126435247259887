import {
  alignItems,
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  borderRadius,
  combineCls,
  content,
  display,
  dropShadow,
  gradientColorStops,
  height,
  inset,
  padding,
  position,
  transitionDuration,
  transitionProperty,
  transitionTimingFunction,
  twCls,
} from '@/style';
import { sliderClasses } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { HorizontalSlider, HorizontalSliderProps } from '../HorizontalSlider';

export type HorizontalDimmerProps = HorizontalSliderProps;

const CustomHorizontalSlider = styled(HorizontalSlider)<HorizontalSliderProps>(() => ({
  // height: '56px',
  padding: 0,
  [`& .${sliderClasses.rail}`]: {
    backgroundColor: 'unset',
  },
  [`& .${sliderClasses.mark}`]: {
    height: '14px',
    backgroundColor: '#D9D9D9',
  },
  [`& .${sliderClasses.mark}.${sliderClasses.markActive}`]: {
    height: '14px',
    backgroundColor: 'rgb(35, 150, 253)',
  },
  [`& .${sliderClasses.track}`]: {
    backgroundColor: 'unset',
    border: 'unset',
  },
  [`& .${sliderClasses.thumb}`]: {
    height: '22px',
    width: '2px',
    borderRadius: 'unset',
    backgroundColor: 'rgb(35, 150, 253)',
  },
  [`& .${sliderClasses.thumb}.${sliderClasses.focusVisible}`]: {
    boxShadow: 'none',
  },
  [`& .${sliderClasses.thumb}:hover`]: {
    boxShadow: 'none',
  },
  [`& .${sliderClasses.active}`]: {
    boxShadow: 'none !important',
  },
  [`&.${sliderClasses.active}`]: {
    boxShadow: 'none',
  },
}));

export const HorizontalDimmer = (props: HorizontalDimmerProps): JSX.Element => {
  return (
    <div
      className={combineCls(
        twCls(
          backgroundRepeat('bg-no-repeat'),
          backgroundPosition('bg-center'),
          backgroundSize('bg-contain'),
          backgroundImage('bg-gradient-to-b'),
          gradientColorStops('from-blue-200', 'to-white'),
          borderRadius('rounded-full'),
          height('h-14'),
          display('grid'),
          alignItems('items-center'),
        ),
      )}
    >
      <div
        className={twCls(
          padding('px-6'),
          display('grid'),
          alignItems('items-center'),
          backgroundRepeat('before:bg-no-repeat'),
          backgroundPosition('before:bg-center'),
          backgroundSize('before:bg-contain'),
          backgroundColor('hover:bg-blue-100', 'before:bg-white'),
          position('relative', 'before:absolute'),
          inset('before:inset-2'),
          borderRadius('rounded-full', 'before:rounded-full'),
          height('h-14', 'before:h-10'),
          dropShadow('before:drop-shadow-home'),
          transitionTimingFunction('ease-in-out'),
          transitionDuration('duration-300'),
          content('before:content-space'),
        )}
      >
        <CustomHorizontalSlider
          marks
          step={100 / 30}
          min={0}
          max={100}
          valueLabelDisplay="off"
          {...props}
        />
      </div>
    </div>
  );
};
