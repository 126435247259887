import { SessionProvider } from 'auth/SessionProvider';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';

const App = () => {
  return (
    <SessionProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </SessionProvider>
  );
};

export default App;
