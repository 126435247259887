export const Account = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22">
    <g fill="none" fillRule="evenodd" opacity=".9">
      <g fill="#2396FD" fillRule="nonzero">
        <g>
          <path
            d="M7 8c2.2 0 4-1.8 4-4S9.2 0 7 0 3 1.8 3 4s1.8 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM9 9H5c-2.9 0-5 2.1-5 5v7c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-7c0-2.9-2.1-5-5-5zm3 11H2v-6c0-1.8 1.2-3 3-3h4c1.8 0 3 1.2 3 3v6z"
            transform="translate(-180.000000, -433.000000) translate(180.000000, 433.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);
