export enum DataCategory {
  Logbook = 'logbook',
  DataSharing = 'data-sharing',
  Location = 'location',
  GeneralInformation = 'general-information',
  Sale = 'sale',
  ConstructionRecord = 'construction-record',
  EnergyRecord = 'energy-record',
  EnvironmentRecord = 'environmental-record',
  ProductRecord = 'product-record',
  ServiceRecord = 'service-record',
  UserGuidance = 'user-guidance',
  SystemConfig = 'system-config',
}

export interface Document {
  dataCategory: DataCategory;
  type: string;
  documentId: string;
  fileName: string;
  documentName: string;
  lastModified: string;
  downloadUrl: string;
}

export enum FileCategory {
  Warranty = 'warranty',
  ArchitecturalDrawing = 'architectural-drawing',
  PersonalInformation = 'personal-information',
  UtilitiesInformation = 'utilities-information',
  Insurance = 'insurance',
  Security = 'security',
  UserGuide = 'user-guide',
  UnitDetails = 'unit-details',
  ConstructionInformation = 'construction-information',
}
