import { DateTime } from 'luxon';
import { EnvironmentalMonitorChartDatum } from 'models';

export const getSetOfExpectedUtcIsoDateDaily = (
  startUtcIsoDate: string,
  endUtcIsoDate: string,
): Set<string> => {
  const setOfExpectedUtcIsoDates = new Set<string>();
  const endUtcDateTime = DateTime.fromISO(endUtcIsoDate, { zone: 'utc' });
  let currentUtcDateTime = DateTime.fromISO(startUtcIsoDate, { zone: 'utc' });
  while (currentUtcDateTime.toUnixInteger() <= endUtcDateTime.toUnixInteger()) {
    setOfExpectedUtcIsoDates.add(currentUtcDateTime.toISODate());
    currentUtcDateTime = currentUtcDateTime.plus({ day: 1 });
  }
  return setOfExpectedUtcIsoDates;
};

export const getChartDataForEachMissingUtcIsoDatesDaily = (
  setOfMissingExpectedUtcIsoDates: Set<string>,
): (readonly [localIsoDate: string, datum: EnvironmentalMonitorChartDatum])[] => {
  const unsortedMissingExpectedChartData: (readonly [
    localIsoDate: string,
    datum: EnvironmentalMonitorChartDatum,
  ])[] = [];
  setOfMissingExpectedUtcIsoDates.forEach((missingUtcIsoDate) => {
    const currentLocalDateTime = DateTime.fromISO(missingUtcIsoDate, { zone: 'utc' }).toLocal();
    const missingEntryChartDatum: EnvironmentalMonitorChartDatum = {
      timeIntervalLabel: currentLocalDateTime.day,
      avg: undefined,
      minMax: undefined,
    };
    unsortedMissingExpectedChartData.push([
      currentLocalDateTime.toISODate(),
      missingEntryChartDatum,
    ] as const);
  });
  return unsortedMissingExpectedChartData;
};
