import { useCallback, useEffect, useState, MouseEvent, useRef } from 'react';
import { AirQualitySensorsData, RoomData } from 'models/globalHomeModels';
import { GlobalHomeService } from 'services/GlobalHomeService';
import { EnvironmentSensorType, Spinner } from '@mgh-app/component-library';
import { useAuth } from 'hooks/useAuth';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexWrap,
  fontSize,
  fontWeight,
  gap,
  justifyContent,
  padding,
  textAlign,
  twCls,
  width,
} from 'style';
import { SensorsUtils } from '../../utils';

import { ReactComponent as Co2Icon } from '../../assets/environmentMonitoring/co2.svg';
import { ReactComponent as HumidityIcon } from '../../assets/environmentMonitoring/humidity.svg';
import { ReactComponent as VocIcon } from '../../assets/environmentMonitoring/voc.svg';
import { ReactComponent as LuxIcon } from '../../assets/environmentMonitoring/lux.svg';
import { ReactComponent as ParticulatesIcon } from '../../assets/environmentMonitoring/particulates.svg';
import { ReactComponent as DewPointIcon } from '../../assets/environmentMonitoring/dewPoint.svg';
import { ReactComponent as AirPressureIcon } from '../../assets/environmentMonitoring/airPressure.svg';
import {
  getRoomPreviousThirtyDaysAirQualityAverages,
  getPerUnitPreviousThirtyDaysAverageAirQualityValues,
} from 'services';
import {
  EnvironmentalMonitoringDisplayItems,
  HistoricalAirQualityAverages,
  SensorsRangeValues,
} from '../../models';
import { HistEnvMonAirQualityDataContainer } from 'components';
import { getAllRoomsAirQualitySensorData } from 'services/hist-env-mon-data-services/roomAirQualitySensorDataService';

export const environmentalMonitoringDisplayItems: EnvironmentalMonitoringDisplayItems = {
  co2: {
    historicalAirQualityKey: 'avg_co2',
    title: <h3>Carbon Dioxide</h3>,
    icon: <Co2Icon />,
    type: EnvironmentSensorType.CO2,
    showsAverageAndMeaning: true,
    trim: 0,
  },
  voc: {
    historicalAirQualityKey: 'avg_voc',
    title: <h3>VOC</h3>,
    type: EnvironmentSensorType.VOC,
    icon: <VocIcon />,
    showsAverageAndMeaning: true,
    trim: 0,
  },
  rh: {
    historicalAirQualityKey: 'avg_rh',
    title: <h3>Humidity</h3>,
    type: EnvironmentSensorType.RH,
    icon: <HumidityIcon />,
    showsAverageAndMeaning: true,
    trim: 0,
  },
  dew: {
    historicalAirQualityKey: 'avg_dew',
    title: <h3>Dew Point</h3>,
    type: EnvironmentSensorType.Dew,
    icon: <DewPointIcon />,
    showsAverageAndMeaning: true,
    trim: 1,
  },
  lux: {
    historicalAirQualityKey: 'avg_lux',
    title: <h3>Lux Level</h3>,
    type: EnvironmentSensorType.Lux,
    showsAverageAndMeaning: false,
    icon: <LuxIcon />,
    trim: 0,
  },
  air_pressure: {
    historicalAirQualityKey: 'avg_air_pressure',
    title: <h3>Air Pressure</h3>,
    type: EnvironmentSensorType.AirPressure,
    icon: <AirPressureIcon />,
    showsAverageAndMeaning: true,
    trim: 2,
  },
  // sound_pressure: {
  //   historicalAirQualityKey: 'avg_sound_pressure',
  //   title: <h3>Sound Pressure</h3>,
  //   type: EnvironmentSensorType.SoundPressure,
  //   icon: <SoundPressure />,
  //   showsAverageAndMeaning: true,
  //   trim: 0,
  // },
  // pm1: {
  //   historicalAirQualityKey: 'avg_pm1',
  //   title: (
  //     <h3>
  //       PM<sub>1</sub>
  //     </h3>
  //   ),
  //   type: EnvironmentSensorType.PM1,
  //   icon: <ParticulatesIcon />,
  //   showsAverageAndMeaning: true,
  //   trim: 0,
  // },
  pm25: {
    historicalAirQualityKey: 'avg_pm25',
    title: (
      <h3>
        PM<sub>2.5</sub>
      </h3>
    ),
    type: EnvironmentSensorType.PM25,
    icon: <ParticulatesIcon />,
    showsAverageAndMeaning: true,
    trim: 0,
  },
  // pm4: {
  //   historicalAirQualityKey: 'avg_pm4',
  //   title: (
  //     <h3>
  //       PM<sub>4</sub>
  //     </h3>
  //   ),
  //   type: EnvironmentSensorType.PM4,
  //   icon: <ParticulatesIcon />,
  //   showsAverageAndMeaning: true,
  //   trim: 0,
  // },
  pm10: {
    historicalAirQualityKey: 'avg_pm10',
    title: (
      <h3>
        PM<sub>10</sub>
      </h3>
    ),
    type: EnvironmentSensorType.PM10,
    icon: <ParticulatesIcon />,
    showsAverageAndMeaning: true,
    trim: 0,
  },
};

const getUnitOrRoomToggleButton = (
  unitOrRoomId: string,
  unitOrRoomName: string,
  isSelected: boolean,
  handleUnitOrRoomChange: (event: MouseEvent<HTMLButtonElement>) => Promise<void>,
) => (
  <button
    type="button"
    key={unitOrRoomId}
    value={unitOrRoomId}
    onClick={handleUnitOrRoomChange}
    className={twCls(
      backgroundColor(isSelected ? 'bg-white' : 'bg-grey-100'),
      borderWidth(isSelected ? 'border' : 'border-0'),
      borderColor('border-grey-400'),
      borderRadius('rounded-lg'),
      width('w-48'),
      padding('px-3', 'py-1'),
      fontWeight('font-medium'),
    )}
  >
    {unitOrRoomName}
  </button>
);

export const EnvironmentMonitoringPage = (): JSX.Element => {
  const { user } = useAuth();
  const [rooms, setRooms] = useState<RoomData[] | null>(null);
  const [airQualitySensorsData, setAirQualitySensorsData] = useState<
    AirQualitySensorsData[] | null
  >(null);
  const [noSensorsAvailable, setNoSensorsAvailable] = useState(false);
  const [selectedUnitOrRoomId, setSelectedUnitOrRoomId] = useState<string | null>(null);
  const [unitHistValues, setUnitHistValues] = useState<HistoricalAirQualityAverages | null>(null);
  const [roomsHistValues, setRoomsHistValues] = useState<Map<
    string,
    HistoricalAirQualityAverages
  > | null>(null);
  const [sensorsRangeValues, setSensorsRangeValues] = useState<SensorsRangeValues | null>(null);
  const [noUnitDataAvailable, setNoUnitDataAvailable] = useState(false);

  const fetchInitialised = useRef(false);

  const getAirQualitySensorsData = useCallback(
    async (roomsData: RoomData[] | null, roomId: string) => {
      if (!roomsData) {
        setNoSensorsAvailable(true);
        return;
      }

      const room = roomsData?.find((r) => r.roomId === roomId);
      if (!room?.airQualitySensorsId) {
        setNoSensorsAvailable(true);
        return;
      }
      try {
        const sensors = await GlobalHomeService.getSensors(room.airQualitySensorsId);
        const sensorsData = SensorsUtils.convert(sensors.data);
        setAirQualitySensorsData(sensorsData);
      } catch {
        setNoSensorsAvailable(true);
      }
    },
    [],
  );

  const handleUnitOrRoomChange = async (event: MouseEvent<HTMLButtonElement>) => {
    const newUnitOrRoomId = event.currentTarget.value;
    if (newUnitOrRoomId !== selectedUnitOrRoomId) {
      setSelectedUnitOrRoomId(newUnitOrRoomId);
      setAirQualitySensorsData(null);
      setNoSensorsAvailable(false);
      await getAirQualitySensorsData(rooms, newUnitOrRoomId);
    }
  };

  // Retrieve unit and room data.
  // TODO: Move to React Tool Kit Query
  useEffect(() => {
    (async () => {
      if (fetchInitialised.current) {
        return;
      }
      try {
        fetchInitialised.current = true;
        const roomsData = await getAllRoomsAirQualitySensorData();
        if (
          roomsData &&
          user?.unitId &&
          !selectedUnitOrRoomId &&
          !unitHistValues &&
          !roomsHistValues
        ) {
          setRooms(roomsData);
          setSelectedUnitOrRoomId(user.unitId);
          try {
            const [sensorsRangeValues, unitHistValues, roomsHistValues] = await Promise.all([
              GlobalHomeService.getRanges(),
              getPerUnitPreviousThirtyDaysAverageAirQualityValues(user.unitId),
              getRoomPreviousThirtyDaysAirQualityAverages(user.unitId),
            ]);
            unitHistValues ? setUnitHistValues(unitHistValues) : setNoUnitDataAvailable(true);
            setRoomsHistValues(roomsHistValues);
            setSensorsRangeValues(sensorsRangeValues?.data);
          } catch (e) {
            console.error('Error fetching unit historical data or sensors range data', e);
          }
        }
      } catch (e) {
        console.error('Error fetching rooms', e);
      }
    })();
  }, [
    rooms,
    selectedUnitOrRoomId,
    unitHistValues,
    roomsHistValues,
    user?.unitId,
    getAirQualitySensorsData,
    sensorsRangeValues,
  ]);

  return (
    <>
      <div className={twCls(display('hidden', 'lg:flex'), padding('pb-6'))}>
        My Home
        <span className={twCls(padding('px-2'))}>|</span>
        <span className={twCls(fontWeight('font-bold'))}>Environmental Monitoring</span>
      </div>
      <div
        className={twCls(
          fontSize('text-3xl', 'md:text-5xl', 'lg:text-6xl'),
          padding('pb-8', 'md:pb-10', 'lg:pb-12'),
          fontWeight('font-medium'),
        )}
      >
        Environmental Monitoring
      </div>
      {rooms && user?.unitId ? (
        <>
          <div
            className={twCls(
              display('flex'),
              flexWrap('flex-wrap'),
              gap('gap-x-4', 'gap-y-1'),
              justifyContent('justify-center'),
            )}
          >
            <>
              {getUnitOrRoomToggleButton(
                user.unitId,
                'Overall',
                user.unitId === selectedUnitOrRoomId,
                handleUnitOrRoomChange,
              )}
              {rooms &&
                rooms.map(({ roomId, roomName }) =>
                  getUnitOrRoomToggleButton(
                    roomId,
                    roomName,
                    roomId === selectedUnitOrRoomId,
                    handleUnitOrRoomChange,
                  ),
                )}
            </>
          </div>
          {(user.unitId &&
            selectedUnitOrRoomId === user.unitId &&
            (unitHistValues ? (
              <HistEnvMonAirQualityDataContainer
                unitId={user.unitId}
                isUnit={true}
                roomData={undefined}
                sensorsRangeValues={sensorsRangeValues}
                historicalAverageData={unitHistValues}
              />
            ) : noUnitDataAvailable ? (
              <div className={twCls(padding('p-8'), width('w-full'), textAlign('text-center'))}>
                <p>No Historical Data Available</p>
              </div>
            ) : (
              // Unit selection loading.
              <Spinner />
            ))) ||
            (rooms && airQualitySensorsData ? (
              selectedUnitOrRoomId &&
              roomsHistValues &&
              roomsHistValues.has(selectedUnitOrRoomId) && (
                <HistEnvMonAirQualityDataContainer
                  unitId={user.unitId}
                  roomId={selectedUnitOrRoomId}
                  isUnit={false}
                  roomData={airQualitySensorsData}
                  sensorsRangeValues={sensorsRangeValues}
                  historicalAverageData={roomsHistValues.get(selectedUnitOrRoomId)}
                />
              )
            ) : noSensorsAvailable ? (
              <div className={twCls(padding('p-8'), width('w-full'), textAlign('text-center'))}>
                <p>No Sensors Available</p>
              </div>
            ) : (
              // Room selection loading.
              <Spinner />
            ))}
        </>
      ) : (
        // Selected unit or room sensors loading.
        <Spinner />
      )}
    </>
  );
};
