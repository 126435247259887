import { EnvironmentSensorType } from '@mgh-app/component-library';
import {
  EnvMonitoringAggregatesAccumulator,
  EnvironmentalMonitorChartDatum,
  HistoricalEntry,
} from 'models';
import { baseManagementUrl } from 'services/apiConfig';
import {
  historialEnvironmentalDataApi,
  accumulateSingleSensorTypeFromHistoricalEntry,
} from '../sharedUtils';
import {
  getChartDataForEachMissingUtcIsoDateTime,
  getPreviousTwentyFourHourPeriodUTCStartAndEndHours,
  getPreviousTwentyFourHoursUTCDateRange,
  getSetOfExpectedUtcIsoDateTimesHourly,
  sortPreviousTwentyFourHourPeriodChartData,
} from './hourlySharedUtilities';
import { DateTime } from 'luxon';

// ROOM - Hourly - Env Mon Chart Data

export const getRoomHourlyExtensibleAggregateDataForEnvMonChart = async (
  unitId: string,
  type: EnvironmentSensorType,
  roomId?: string,
): Promise<EnvironmentalMonitorChartDatum[] | null> => {
  if (!roomId) {
    return null;
  }
  const roomIdToHistoricalAirQualityAverages = new Map<
    readonly [string, number],
    EnvMonitoringAggregatesAccumulator
  >();

  // Get the start and end date for the search.
  const { startUtcIsoDate, endUtcIsoDate } = getPreviousTwentyFourHoursUTCDateRange(false);
  const { startUtcHour, endUtcHour } = getPreviousTwentyFourHourPeriodUTCStartAndEndHours();

  // Populate set containing all utcIsoDateTimes expected in range.
  const setOfExpectedUtcIsoDateTimes = getSetOfExpectedUtcIsoDateTimesHourly(
    startUtcIsoDate,
    startUtcHour,
    endUtcIsoDate,
    endUtcHour,
  );

  const perRoomHistoricalEntries: HistoricalEntry[] | undefined =
    await historialEnvironmentalDataApi<HistoricalEntry[]>(
      `${baseManagementUrl}/units/${unitId}/rooms/historicaldata/air-quality/hourly?start_date=${startUtcIsoDate}&end_date=${endUtcIsoDate}&start_hour=${startUtcHour}&end_hour=${endUtcHour}`,
    );

  if (!perRoomHistoricalEntries) {
    return null;
  }

  // Group the entries for the specified room by day, accumulating th, respectively.
  perRoomHistoricalEntries.forEach((entry) => {
    if (entry.roomid !== roomId || entry.hour === undefined) {
      return;
    }

    const currentUtcIsoDateTime = DateTime.fromISO(entry.date, { zone: 'utc' }).set({
      hour: entry.hour,
    });

    // Remove each utcIsoDateTime from expected set for the given range for each utcIsoDateTime encountered in the received data.
    const isFirstEncounter = setOfExpectedUtcIsoDateTimes.delete(currentUtcIsoDateTime.toISO());

    // Group and prepare data for aggregation of averages, per date.
    !isFirstEncounter
      ? // If an entry for the current date is found, update it from the current entry.
        roomIdToHistoricalAirQualityAverages.set(
          [entry.date, entry.hour],
          accumulateSingleSensorTypeFromHistoricalEntry(
            entry,
            type,
            roomIdToHistoricalAirQualityAverages.get([entry.date, entry.hour]),
          ),
        )
      : // If an entry for the current date is not found, create a new accumulator from the current entry.
        roomIdToHistoricalAirQualityAverages.set(
          [entry.date, entry.hour],
          accumulateSingleSensorTypeFromHistoricalEntry(entry, type),
        );
  });

  // Complete calculation of average aggregate
  const unsortedSortData: (readonly [
    localDateTime: DateTime,
    datum: EnvironmentalMonitorChartDatum,
    // Add in missing data chart data.
  ])[] = getChartDataForEachMissingUtcIsoDateTime(setOfExpectedUtcIsoDateTimes);
  roomIdToHistoricalAirQualityAverages.forEach((accumulator, [utcIsoDate, utcHour]) => {
    // Perform average calculation for accumulator and convert to a list of date string to evn monitoring datum tuple form to allow for sorting to take place.
    let currentChartDatum = convertAccumulatorToHourlyLocalTimeLabeledChartDatum(
      accumulator,
      DateTime.fromISO(utcIsoDate, { zone: 'utc' }).set({ hour: utcHour }),
    );

    // If current chart datum represents the unfinished time interval that contains the present moment,
    // the average must be incomplete, so set it to undefined so that it will not be presented on the chart.
    if (utcIsoDate === DateTime.utc().toISODate() && utcHour === DateTime.utc().hour) {
      currentChartDatum = { ...currentChartDatum, avg: undefined };
    }

    unsortedSortData.push([
      DateTime.fromISO(utcIsoDate, { zone: 'utc' }).set({ hour: utcHour }).toLocal(),
      currentChartDatum,
    ] as const);
  });

  const sortedChartData = sortPreviousTwentyFourHourPeriodChartData(unsortedSortData);

  return sortedChartData;
};

export const convertAccumulatorToHourlyLocalTimeLabeledChartDatum = (
  accumulator: EnvMonitoringAggregatesAccumulator,
  utcDateTime: DateTime,
): EnvironmentalMonitorChartDatum => {
  const chartDatum: EnvironmentalMonitorChartDatum = {
    // Hour property is zero indexed (0-23), so add 1 to show data labeled on chart as spanning from hours 1 to 24.
    timeIntervalLabel: utcDateTime.toLocal().hour + 1,
    avg: accumulator.runningTotal / accumulator.runningCount,
    minMax: [accumulator.runningMin, accumulator.runningMax],
  };
  return chartDatum;
};
