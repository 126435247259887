import { Button } from '@mgh-app/component-library';
import { MainLayoutContext } from 'layouts/MainLayout';
import { useContext } from 'react';
import { display, fontSize, fontWeight, padding, twCls } from 'style';

export const MyAccountPage = () => {
  const { showDataConsentModal } = useContext(MainLayoutContext);
  return (
    <div className={twCls(display('grid'))}>
      <div
        className={twCls(
          fontSize('lg:text-3xl'),
          fontWeight('font-medium'),
          padding('pb-8', 'md:pb-10', 'lg:pb-12'),
        )}
      >
        My Account
      </div>
      <div>
        <Button onClick={() => showDataConsentModal(true)}>Data Consent Settings</Button>
      </div>
    </div>
  );
};
