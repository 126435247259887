import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  combineCls,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  lineHeight,
  padding,
  twCls,
} from 'style';

export interface FlaggedTextDisplayProps {
  primaryContents: JSX.Element;
  flagContents: JSX.Element;
}

// TODO: Abstract to shared component library

export const FlaggedTextDisplay = ({
  primaryContents,
  flagContents,
}: FlaggedTextDisplayProps): JSX.Element => {
  return (
    <div className={combineCls(twCls(display('flex'), flexDirection('flex-row')))}>
      <div
        className={combineCls(
          twCls(
            borderWidth('border'),
            borderRadius('rounded'),
            borderColor('border-grey-400'),
            fontSize('text-xs'),
            display('flex'),
            alignItems('items-center'),
            fontWeight('font-bold'),
            padding('px-2'),
          ),
        )}
      >
        {primaryContents}
      </div>
      <div className={twCls(padding('pl-1'))}>
        <div
          className={twCls(
            backgroundColor('bg-grey-200'),
            fontSize('text-lg'),
            lineHeight('leading-tight'),
            display('flex'),
            alignItems('items-center'),
            borderRadius('rounded-l-full'),
            padding('px-3'),
          )}
        >
          {flagContents}
        </div>
      </div>
    </div>
  );
};
