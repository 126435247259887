import { EnvironmentSensorType } from '@mgh-app/component-library';
import { AirQualitySensorsData } from 'models/globalHomeModels';
import { SensorRangeValue, SensorsRangeValues } from '../models';
import { SensorData } from 'services/GlobalHomeService';

const INFINITY_REG_EX = /infinity/gi;
const RADIX_CHARACTER_REG_EX = /\./g;
const RANGE_KEY_SEPARATOR_CHAR = '-';

const convertRangeSubStringToValue = (rangeSubString: string): number | null => {
  let rangeValue: number | null = null;
  // If is infinity then leave sentinel value.
  if (rangeSubString.match(INFINITY_REG_EX) === null) {
    // Not infinity, so determine if is float.
    if (rangeSubString.match(RADIX_CHARACTER_REG_EX) === null) {
      // Is integer, parse.
      rangeValue = Number.parseInt(rangeSubString);
    } else {
      // Is float, parse.
      rangeValue = Number.parseFloat(rangeSubString);
    }
  }
  // Is infinity, leave as null.
  return rangeValue;
};

const parseRangeValuesFromKey = (rangeName: string, rangeKey: string): SensorRangeValue => {
  const [minSubString, maxSubString] = rangeKey.split(RANGE_KEY_SEPARATOR_CHAR);

  // Set sentinel, 'infinity', values for max and min values.
  const sensorRangeValue: SensorRangeValue = {
    maxValue: Number.MAX_VALUE,
    minValue: -1 * Number.MAX_VALUE,
    name: rangeName,
  };

  // Set max and min values if not infinity.
  sensorRangeValue.minValue =
    convertRangeSubStringToValue(minSubString) ?? sensorRangeValue.minValue;
  sensorRangeValue.maxValue =
    convertRangeSubStringToValue(maxSubString) ?? sensorRangeValue.maxValue;

  // Return the sensor range values.
  return sensorRangeValue;
};
const fitsRange = (value: number, currentSensorRangeValue: SensorRangeValue): boolean =>
  value >= currentSensorRangeValue.minValue && value <= currentSensorRangeValue.maxValue;

export const convertValueToRangeLabel = (
  value: number,
  type: EnvironmentSensorType,
  sensorsRangeValues: SensorsRangeValues,
): string | null => {
  // Get the current sensor type's ranges.
  const currentSensorTypeRanges = sensorsRangeValues[type];
  let fitRangeName: null | string = null;
  if (!currentSensorTypeRanges) {
    // Sensor has no defined ranges.
    console.error(`Environmental sensor type: ${type}, has no defined sensor ranges.`);
    return null;
  }
  for (const [rangeKey, rangeName] of Object.entries(currentSensorTypeRanges)) {
    // For every range in the current type, parse the range values
    const currentSensorRangeValue = parseRangeValuesFromKey(rangeName, rangeKey);
    // determine if value is a fit.
    if (fitsRange(value, currentSensorRangeValue)) {
      fitRangeName = rangeName;
      break;
    }
  }
  if (fitRangeName) {
    return fitRangeName;
  }
  // Matches no range for current sensor type, this is an error: throw!
  throw new Error('A sensor with defined value ranges, has a value that matches none of them');
};

export const SensorsUtils = {
  convert: (sensorsData: SensorData[]): AirQualitySensorsData[] => {
    const airQualitySensorsData: AirQualitySensorsData[] = [];

    for (const sensor of sensorsData) {
      const sensorProps = Object.getOwnPropertyNames(sensor);
      for (const prop of sensorProps) {
        switch (prop) {
          case EnvironmentSensorType.CO2:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.CO2,
              value: sensor[EnvironmentSensorType.CO2],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.RH:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.RH,
              value: sensor[EnvironmentSensorType.RH],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.VOC:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.VOC,
              value: sensor[EnvironmentSensorType.VOC],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.Dew:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.Dew,
              value: sensor[EnvironmentSensorType.Dew],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.Lux:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.Lux,
              value: sensor[EnvironmentSensorType.Lux],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.PM1:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.PM1,
              value: sensor[EnvironmentSensorType.PM1],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.PM25:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.PM25,
              value: sensor[EnvironmentSensorType.PM25],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.PM4:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.PM4,
              value: sensor[EnvironmentSensorType.PM4],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.PM10:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.PM10,
              value: sensor[EnvironmentSensorType.PM10],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.Temperature:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.Temperature,
              value: sensor[EnvironmentSensorType.Temperature],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.AirPressure:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.AirPressure,
              value: sensor[EnvironmentSensorType.AirPressure],
              label: sensor['label'],
            });
            break;
          case EnvironmentSensorType.SoundPressure:
            airQualitySensorsData.push({
              type: EnvironmentSensorType.SoundPressure,
              value: sensor[EnvironmentSensorType.SoundPressure],
              label: sensor['label'],
            });
            break;
        }
      }
    }

    return airQualitySensorsData;
  },
};
