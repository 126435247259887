import { EnvironmentSensorType } from '@mgh-app/component-library';
import { DateTime } from 'luxon';
import {
  EnvMonitoringAggregatesAccumulator,
  HistoricalEntry,
  EnvironmentalMonitorChartDatum,
} from 'models';
import { baseManagementUrl } from 'services';
import {
  historialEnvironmentalDataApi,
  accumulateSingleSensorTypeFromHistoricalEntry,
  sortIsoDateKeyedTupleArray,
} from '../sharedUtils';
import { EnvironmentalMonitoringChartGetRoomData } from 'components/historical-environmental-monitoring/EnvironmentalMonitorDetails';
import {
  MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT,
  getChartDataForEachMissingBeginningOfTheMonthUtcIsoDate,
  getPreviousTwelveMonthUTCDateRange,
  getSetOfExpectedBeginningOfMonthUtcIsoDates,
} from './monthlySharedUtilities';

// ROOM - Monthly - Env Mon Chart Data

export const getRoomMonthlyExtensibleAggregateDataForEnvMonChart: EnvironmentalMonitoringChartGetRoomData =
  async (unitId: string, type: EnvironmentSensorType, roomId?: string) => {
    if (!roomId) {
      return null;
    }

    const roomIdToHistoricalAirQualityAverages = new Map<
      string,
      EnvMonitoringAggregatesAccumulator
    >();

    // Get the start and end date for the search.
    const { beginningOfTheMonthStartUtcIsoDate, endOfTheMonthEndUtcIsoDate } =
      getPreviousTwelveMonthUTCDateRange(false);

    // Populate set containing all beginningOfMonthUtcIsoDates expected in range.
    // Named 'beginningOfTheMonth...' because all UTC ISO dates returned will be the first of the month for each month.
    const setOfExpectedBeginningOfMonthUtcIsoDates = getSetOfExpectedBeginningOfMonthUtcIsoDates(
      beginningOfTheMonthStartUtcIsoDate,
      endOfTheMonthEndUtcIsoDate,
    );

    const perRoomHistoricalEntries: HistoricalEntry[] | undefined =
      await historialEnvironmentalDataApi<HistoricalEntry[]>(
        `${baseManagementUrl}/units/${unitId}/rooms/historicaldata/air-quality/monthly?start_date=${beginningOfTheMonthStartUtcIsoDate}&end_date=${endOfTheMonthEndUtcIsoDate}`,
      );

    if (!perRoomHistoricalEntries) {
      return null;
    }

    // Group the entries for the specified room by day, accumulating th, respectively.
    perRoomHistoricalEntries.forEach((entry) => {
      if (entry.roomid !== roomId) {
        return;
      }

      // Remove each utcIsoDateTime from expected set for the given range for each utcIsoDateTime encountered in the received data.
      const isFirstEncounter = setOfExpectedBeginningOfMonthUtcIsoDates.delete(entry.date);

      // Group and prepare data for aggregation of averages, per date.
      !isFirstEncounter
        ? // If an entry for the current date is found, update it from the current entry.
          roomIdToHistoricalAirQualityAverages.set(
            entry.date,
            accumulateSingleSensorTypeFromHistoricalEntry(
              entry,
              type,
              roomIdToHistoricalAirQualityAverages.get(entry.date),
            ),
          )
        : // If an entry for the current date is not found, create a new accumulator from the current entry.
          roomIdToHistoricalAirQualityAverages.set(
            entry.date,
            accumulateSingleSensorTypeFromHistoricalEntry(entry, type),
          );
    });

    // Complete calculation of average aggregate
    const unsortedSortData: (readonly [string, EnvironmentalMonitorChartDatum])[] =
      // Add in missing data chart data.
      getChartDataForEachMissingBeginningOfTheMonthUtcIsoDate(
        setOfExpectedBeginningOfMonthUtcIsoDates,
      );
    roomIdToHistoricalAirQualityAverages.forEach((accumulator, date) => {
      let chartDatum = convertAccumulatorToMonthlyLocalTimeLabeledChartDatum(accumulator, date);

      // The time interval containing the present moment is incomplete so do not show the average.
      if (date === DateTime.utc().startOf('month').toISODate()) {
        chartDatum = { ...chartDatum, avg: undefined };
      }
      // Perform average calculation for accumulator and convert to a list of date string to evn monitoring datum tuple form to allow for sorting to take place.
      unsortedSortData.push([date, chartDatum] as const);
    });

    const sortedSortData = sortIsoDateKeyedTupleArray(unsortedSortData);

    const sortedChartData: EnvironmentalMonitorChartDatum[] = sortedSortData.map(([, datum]) => {
      datum.timeIntervalLabel = DateTime.local()
        .set({
          month: +datum.timeIntervalLabel,
        })
        .toFormat(MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT);
      return datum;
    });

    return sortedChartData;
  };

const convertAccumulatorToMonthlyLocalTimeLabeledChartDatum = (
  accumulator: EnvMonitoringAggregatesAccumulator,
  isoDate: string,
): EnvironmentalMonitorChartDatum => {
  const chartDatum: EnvironmentalMonitorChartDatum = {
    timeIntervalLabel: DateTime.fromISO(isoDate, { zone: 'utc' }).toLocal().month,
    avg: accumulator.runningTotal / accumulator.runningCount,
    minMax: [accumulator.runningMin, accumulator.runningMax],
  };
  return chartDatum;
};
