import { EnvironmentSensorType } from '@mgh-app/component-library';
import { SensorsRangeValues, HistoricalAirQualityAverages } from 'models';
import { AirQualitySensorsData } from 'models/globalHomeModels';
import { display, flexDirection, gap, margin, padding, twCls } from 'style';
import { isDisplayedSensorType, convertValueToRangeLabel } from 'utils';
import { HistEnvMonAccordionItemSummary } from '../HistEnvMonAccordionItemSummary';
import { environmentalMonitoringDisplayItems } from 'pages/EnvironmentMonitoringPage';

export interface HistEnvMonAirQualityDataContainerProps {
  unitId: string;
  roomId?: string;
  isUnit: boolean;
  roomData?: AirQualitySensorsData[];
  sensorsRangeValues: SensorsRangeValues | null;
  historicalAverageData?: HistoricalAirQualityAverages;
}

export const HistEnvMonAirQualityDataContainer = ({
  unitId,
  roomId,
  isUnit,
  roomData,
  sensorsRangeValues,
  historicalAverageData,
}: HistEnvMonAirQualityDataContainerProps): JSX.Element => {
  const getSensorCurrentValue = (sensorType: EnvironmentSensorType) =>
    roomData?.find((s) => s.type === sensorType)?.value ?? undefined;

  const getSensorLabel = (sensorType: EnvironmentSensorType) =>
    roomData?.find((s) => s.type === sensorType)?.label ?? undefined;
  return (
    <div
      className={twCls(
        margin('mt-4', 'md:mt-8'),
        padding('px-2'),
        display('flex'),
        flexDirection('flex-col'),
        gap('gap-y-4'),
      )}
    >
      {Object.values(environmentalMonitoringDisplayItems).map(
        ({ title, type, historicalAirQualityKey, showsAverageAndMeaning, trim }) => {
          const historicalValue = historicalAverageData?.[historicalAirQualityKey];
          const currentValue = getSensorCurrentValue(type);
          return isDisplayedSensorType(type) &&
            (historicalValue !== undefined || currentValue !== undefined) ? (
            <div key={type}>
              <HistEnvMonAccordionItemSummary
                isUnit={isUnit}
                unitId={unitId}
                roomId={roomId}
                title={title}
                type={type}
                showsAverageAndMeaning={showsAverageAndMeaning}
                trim={trim}
                currentValue={isUnit ? undefined : currentValue}
                thirtyDayAverageValue={historicalValue}
                label={
                  isUnit
                    ? historicalValue && sensorsRangeValues
                      ? convertValueToRangeLabel(historicalValue, type, sensorsRangeValues) || ''
                      : undefined
                    : getSensorLabel(type)
                }
              />
            </div>
          ) : null;
        },
      )}
    </div>
  );
};
