import { EnvironmentSensorType } from '@mgh-app/component-library';
import { DateTime } from 'luxon';
import { HistoricalEntry, EnvironmentalMonitorChartDatum, SensorAggregates } from 'models';
import { baseManagementUrl } from 'services/apiConfig';
import { historialEnvironmentalDataApi, sortIsoDateKeyedTupleArray } from '../sharedUtils';
import {
  MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT,
  getChartDataForEachMissingBeginningOfTheMonthUtcIsoDate,
  getPreviousTwelveMonthUTCDateRange,
  getSetOfExpectedBeginningOfMonthUtcIsoDates,
} from './monthlySharedUtilities';

// UNIT - Monthly - Env Mon Chart Data

export const getUnitMonthlyExtensibleAggregationDataForEnvMonChart = async (
  unitId: string,
  type: EnvironmentSensorType,
) => {
  // Get the start and end date for the search.
  const { beginningOfTheMonthStartUtcIsoDate, endOfTheMonthEndUtcIsoDate } =
    getPreviousTwelveMonthUTCDateRange(false);

  // Populate set containing all beginningOfMonthUtcIsoDates expected in range.
  // Named 'beginningOfTheMonth...' because all UTC ISO dates returned will be the first of the month for each month.
  const setOfExpectedBeginningOfMonthUtcIsoDates = getSetOfExpectedBeginningOfMonthUtcIsoDates(
    beginningOfTheMonthStartUtcIsoDate,
    endOfTheMonthEndUtcIsoDate,
  );

  const unitHistoricalEntries: HistoricalEntry[] | undefined = await historialEnvironmentalDataApi<
    HistoricalEntry[]
  >(
    `${baseManagementUrl}/units/${unitId}/historicaldata/air-quality/monthly?start_date=${beginningOfTheMonthStartUtcIsoDate}&end_date=${endOfTheMonthEndUtcIsoDate}`,
  );

  if (!unitHistoricalEntries) {
    return null;
  }

  let unsortedSortData: (readonly [string, EnvironmentalMonitorChartDatum])[] = [];
  unitHistoricalEntries.forEach((entry) => {
    let chartDatum = convertHistoricalEntryToMonthlyEnvironmentalMonitorLocalChartDatum(
      entry,
      type,
    );

    // The interval including the present moment is not complete so do not display its average.
    if (entry.date === DateTime.utc().startOf('month').toISODate()) {
      chartDatum = { ...chartDatum, avg: undefined };
    }
    // Remove each beginningOfMonthUtcIsoDates from expected set for the given range for each beginningOfMonthUtcIsoDates encountered in the received data.
    setOfExpectedBeginningOfMonthUtcIsoDates.delete(entry.date);

    unsortedSortData.push([entry.date, chartDatum] as const);
  });

  // Add in missing data chart data.
  unsortedSortData = [
    ...unsortedSortData,
    ...getChartDataForEachMissingBeginningOfTheMonthUtcIsoDate(
      setOfExpectedBeginningOfMonthUtcIsoDates,
    ),
  ];

  const sortedSortData = sortIsoDateKeyedTupleArray(unsortedSortData);

  const sortedChartData: EnvironmentalMonitorChartDatum[] = sortedSortData.map(
    ([, currentDatum]) => {
      currentDatum.timeIntervalLabel = DateTime.now()
        .set({ month: +currentDatum.timeIntervalLabel })
        .toFormat(MONTHLY_INTERVAL_CHART_DISPLAY_FORMAT);
      return currentDatum;
    },
  );
  return sortedChartData;
};

const convertHistoricalEntryToMonthlyEnvironmentalMonitorLocalChartDatum = (
  entry: HistoricalEntry,
  type: EnvironmentSensorType,
): EnvironmentalMonitorChartDatum => {
  const fullEntry = new Map<string, SensorAggregates>(Object.entries(entry));
  const currentSensorTypePartial = fullEntry.get(type);
  if (!currentSensorTypePartial) {
    throw Error('Historical entry conversion attempted on unsupported environmental sensor type');
  }

  const environmentalMonitorChartDatum: EnvironmentalMonitorChartDatum = {
    timeIntervalLabel: DateTime.fromISO(entry.date, { zone: 'utc' }).toLocal().month,
    avg: currentSensorTypePartial.avg,
    minMax: [currentSensorTypePartial.min, currentSensorTypePartial.max],
  };

  return environmentalMonitorChartDatum;
};
