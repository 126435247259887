export type { Ref, RefAttributes, ReactElement } from 'react';
import { forwardRef } from 'react';

// https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  // eslint-disable-next-line no-unused-vars
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: Ref<T>) => ReactElement | null,
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

export const fixedForwardRef = forwardRef;
