import React, { ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
import { useLazyGetUserDetailsQuery, useLazySignInQuery } from 'services/UsersService';
import { User } from 'models/user';
import {
  clearTokensInLocalStorage,
  getTokensFromLocalStorage,
  setTokensInLocalStorage,
} from 'services/apiConfig';

export interface AuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  token?: string;
  refreshToken?: string;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const signIn = async () => {
  console.log('empty sign in');
};
const signOut = () => {
  console.log('logout');
};

const defaultAuthContext: AuthContextProps = {
  isAuthenticated: false,
  isLoading: false,
  signIn,
  signOut,
};

export const AuthContext = React.createContext<AuthContextProps>(defaultAuthContext);

export interface SessionProviderProps {
  children?: ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const localStorageTokens = getTokensFromLocalStorage();

  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string | undefined>(localStorageTokens.token);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    localStorageTokens.refreshToken,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [signIn] = useLazySignInQuery();
  const [getUserDetails] = useLazyGetUserDetailsQuery();

  useEffect(() => {
    (async () => {
      try {
        if (token && !user) {
          setIsLoading(true);
          const userDetails = await getUserDetails().unwrap();
          setUser(userDetails);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getUserDetails, token, user]);

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        setIsLoading(true);
        const response = await signIn({ email, password }, false).unwrap();
        setToken(response.token);
        setRefreshToken(response.refreshToken);
        setTokensInLocalStorage(response.token, response.refreshToken);

        const user = await getUserDetails().unwrap();
        setUser(user);
      } finally {
        setIsLoading(false);
      }
    },
    [getUserDetails, signIn],
  );

  const logout = useCallback(async () => {
    setToken(undefined);
    setRefreshToken(undefined);
    clearTokensInLocalStorage();
  }, []);

  const value = useMemo(
    () => ({
      user,
      isAuthenticated: !!user,
      isLoading,
      signIn: login,
      signOut: logout,
      token,
      refreshToken,
    }),
    [isLoading, login, logout, refreshToken, token, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
