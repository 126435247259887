import { DateTime } from 'luxon';
import { ReactComponent as PdfFileIcon } from 'assets/pdfFile.svg';
import { display, flexDirection, fontSize, fontWeight, padding, twCls } from 'style';

export interface FileItemProps {
  file?: string;
  title: string;
  subTitle?: string;
  lastModified?: string;
}

export const FileItem = ({ file, title, subTitle, lastModified }: FileItemProps): JSX.Element => {
  return (
    <a href={file}>
      <div className={twCls(display('flex'))}>
        <PdfFileIcon />
        <div className={twCls(display('flex'), flexDirection('flex-col'), padding('px-4'))}>
          <span className={twCls(fontSize('text-lg'), fontWeight('font-medium'))}>{title}</span>
          {subTitle && <span>{subTitle}</span>}
          {lastModified && <span>{DateTime.fromISO(lastModified).toFormat('dd.LL.yyyy')}</span>}
        </div>
      </div>
    </a>
  );
};

export const FakeFileItem = ({ title, subTitle }: FileItemProps): JSX.Element => {
  return (
    <div className={twCls(display('flex'))}>
      <PdfFileIcon />
      <div className={twCls(display('flex'), flexDirection('flex-col'), padding('px-4'))}>
        <span className={twCls(fontSize('text-lg'), fontWeight('font-medium'))}>{title}</span>
        <span>{subTitle}</span>
        <span>{DateTime.now().toFormat('dd.LL.yyyy')}</span>
      </div>
    </div>
  );
};
