import { DateTime } from 'luxon';
import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';

import { CircuitType } from 'services/apiConfig';
import { GlobalHomeService } from 'services/GlobalHomeService';
import {
  AmbeeApiService,
  AmbeeApiWeatherHistDataEntry,
  EXAMPLE_LATITUDE,
  EXAMPLE_LONGITUDE,
} from 'services/AmbeeApiService';
import { HistTemperatureData, useLazyGetHistTemperatureQuery } from 'services/OpenMeteoApiService';

import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  gap,
  height,
  justifyContent,
  padding,
  twCls,
  width,
} from 'style';

import { Spinner } from '@mgh-app/component-library';
// import { ElectricSupplierForm } from 'components/ElectricSupplierForm';
import { EnergyUse, HistoricalEnergyData } from 'components/EnergyUse';

import { ReactComponent as AllEnergyIcon } from 'assets/allEnergy.svg';
import { ReactComponent as HeatingIcon } from 'assets/heating.svg';
import { ReactComponent as LightingIcon } from 'assets/lighting.svg';
import { ReactComponent as SocketsIcon } from 'assets/sockets.svg';

const energyRefreshIntervalMs = 60 * 1000; // 1 minute

enum EnergyMeasureType {
  AllEnergyUse = 'All Energy Use',
  Heating = 'Heating',
  Lighting = 'Lighting',
  Sockets = 'Sockets',
}

interface EnergyMeasureTypeButton {
  type: EnergyMeasureType;
  icon: ReactElement;
}

const EnergyMeasureTypeButtons: EnergyMeasureTypeButton[] = [
  { type: EnergyMeasureType.AllEnergyUse, icon: <AllEnergyIcon /> },
  { type: EnergyMeasureType.Heating, icon: <HeatingIcon /> },
  { type: EnergyMeasureType.Lighting, icon: <LightingIcon /> },
  { type: EnergyMeasureType.Sockets, icon: <SocketsIcon /> },
];

interface TemperatureData {
  temperature: number;
  date: DateTime;
}

const getTemp = (unit: 'day' | 'month', tempData: TemperatureData[], currentDate: DateTime) =>
  tempData &&
  tempData.find(
    (entry) => currentDate.startOf(unit).valueOf() === entry.date.startOf(unit).valueOf(),
  );

export const MyEnergyPage = (): JSX.Element => {
  const [allEnergyWeek, setAllEnergyWeek] = useState<HistoricalEnergyData[]>([]);
  const [allEnergyYear, setAllEnergyYear] = useState<HistoricalEnergyData[]>([]);
  const [heatEnergyWeek, setHeatEnergyWeek] = useState<HistoricalEnergyData[]>([]);
  const [heatEnergyYear, setHeatEnergyYear] = useState<HistoricalEnergyData[]>([]);
  const [lightEnergyWeek, setLightEnergyWeek] = useState<HistoricalEnergyData[]>([]);
  const [lightEnergyYear, setLightEnergyYear] = useState<HistoricalEnergyData[]>([]);
  const [socketsEnergyWeek, setSocketsEnergyWeek] = useState<HistoricalEnergyData[]>([]);
  const [socketsEnergyYear, setSocketsEnergyYear] = useState<HistoricalEnergyData[]>([]);
  const weatherHistDataDailyRef = useRef<TemperatureData[]>();
  const weatherHistDataMonthlyRef = useRef<TemperatureData[]>();
  const [fetchHistTemp] = useLazyGetHistTemperatureQuery();

  const convertWeekArray = (values: number[], currentDate: DateTime) => {
    const weekArray: HistoricalEnergyData[] = [];
    values.forEach((v, i) =>
      weekArray.push({
        index: i,
        indexName: currentDate.minus({ days: i }).toFormat('dd.LL'),
        date: currentDate.minus({ days: i }),
        value: Number((v / 1000).toPrecision(4)),
      }),
    );
    return weekArray;
  };

  const convertYearArray = (values: number[], currentDate: DateTime) => {
    const yearArray: HistoricalEnergyData[] = [];
    values.forEach((v, i) =>
      yearArray.push({
        index: i,
        indexName: currentDate.minus({ months: i }).toFormat('MM/yy'),
        date: currentDate.minus({ months: i }),
        value: Number((v / 1000).toPrecision(4)),
      }),
    );
    return yearArray;
  };

  const getDetailedEnergyData = async (
    tempDailyData?: TemperatureData[],
    tempMonthlyData?: TemperatureData[],
  ) => {
    const temperatureDailyDate = tempDailyData || weatherHistDataDailyRef.current || [];
    const temperatureMonthlyDate = tempMonthlyData || weatherHistDataMonthlyRef.current || [];
    const energyUsage = await GlobalHomeService.getDetailedEnergyUsage();

    const circuits = energyUsage.data;

    // get total energy usage from all the circuits
    const cDays = [0, 0, 0, 0, 0, 0, 0];
    const cMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const heatDays = [0, 0, 0, 0, 0, 0, 0];
    const heatMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const lightDays = [0, 0, 0, 0, 0, 0, 0];
    const lightMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const socketDays = [0, 0, 0, 0, 0, 0, 0];
    const socketMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (const c in circuits) {
      if (circuits[c].hasOwnProperty('days') && circuits[c].hasOwnProperty('months')) {
        const sDays = circuits[c].days;
        const sMonth = circuits[c].months;
        sDays.forEach((d, i) => {
          cDays[i] = Number((cDays[i] + d).toFixed(2));

          if (circuits[c].hasOwnProperty('type')) {
            switch (circuits[c].type) {
              case CircuitType.Heating:
                heatDays[i] = Number((heatDays[i] + d).toFixed(2));
                break;
              case CircuitType.Lighting:
                lightDays[i] = Number((lightDays[i] + d).toFixed(2));
                break;
              case CircuitType.Sockets:
                socketDays[i] = Number((socketDays[i] + d).toFixed(2));
                break;
              default:
                break;
            }
          }
        });
        sMonth.forEach((m, i) => {
          cMonths[i] = Number((cMonths[i] + m).toFixed(2));

          if (circuits[c].hasOwnProperty('type')) {
            switch (circuits[c].type) {
              case CircuitType.Heating:
                heatMonths[i] = Number((heatMonths[i] + m).toFixed(2));
                break;
              case CircuitType.Lighting:
                lightMonths[i] = Number((lightMonths[i] + m).toFixed(2));
                break;
              case CircuitType.Sockets:
                socketMonths[i] = Number((socketMonths[i] + m).toFixed(2));
                break;
              default:
                break;
            }
          }
        });
      }
    }

    const currentDate = DateTime.now().startOf('day');

    // all energy
    const allEnergyWeekArray: HistoricalEnergyData[] = convertWeekArray(cDays, currentDate);
    setAllEnergyWeek(allEnergyWeekArray.reverse());
    const allEnergyYearArray = convertYearArray(cMonths, currentDate);
    setAllEnergyYear(allEnergyYearArray.reverse());

    // heating
    const heatingWeekArray: HistoricalEnergyData[] = convertWeekArray(heatDays, currentDate).map(
      (we) => ({
        ...we,
        temperature: getTemp('day', temperatureDailyDate, we.date)?.temperature,
      }),
    );
    setHeatEnergyWeek(heatingWeekArray.reverse());
    const heatingYearArray = convertYearArray(heatMonths, currentDate).map((we) => ({
      ...we,
      temperature: getTemp('month', temperatureMonthlyDate, we.date)?.temperature,
    }));
    setHeatEnergyYear(heatingYearArray.reverse());

    // lighting
    const lightingWeekArray: HistoricalEnergyData[] = convertWeekArray(lightDays, currentDate);
    setLightEnergyWeek(lightingWeekArray.reverse());
    const lightingYearArray = convertYearArray(lightMonths, currentDate);
    setLightEnergyYear(lightingYearArray.reverse());

    // sockets
    const socketsWeekArray: HistoricalEnergyData[] = convertWeekArray(socketDays, currentDate);
    setSocketsEnergyWeek(socketsWeekArray.reverse());
    const socketsYearArray = convertYearArray(socketMonths, currentDate);
    setSocketsEnergyYear(socketsYearArray.reverse());
  };

  const avg = (array: Array<number>) => array.reduce((sum, val) => sum + val, 0) / array.length;

  const getOpenMeteoData = async () => {
    try {
      const to = DateTime.now();
      const from = to.minus({ months: 4 });
      const weatherData = await fetchHistTemp({
        lat: EXAMPLE_LATITUDE,
        lng: EXAMPLE_LONGITUDE,
        startDate: from.toFormat('yyyy-LL-dd'),
        endDate: to.toFormat('yyyy-LL-dd'),
      }).unwrap();

      weatherHistDataDailyRef.current = weatherData.map((entry) => ({
        date: DateTime.fromISO(entry.date),
        temperature: Number(entry.temperature.toFixed(1)),
      }));

      weatherHistDataMonthlyRef.current = Object.entries(
        weatherData.reduce((acc, val) => {
          const date = DateTime.fromISO(val.date);
          acc[date.valueOf()] = [...(acc[date.valueOf()] ?? []), val];
          return acc;
        }, {} as { [key: number]: HistTemperatureData[] }),
      ).map(
        ([key, group]) =>
          ({
            date: DateTime.fromMillis(Number(key)),
            temperature: Number(avg(group.map((e) => e.temperature)).toFixed(1)),
          } as TemperatureData),
      );
      return { daily: weatherHistDataDailyRef.current, monthly: weatherHistDataMonthlyRef.current };
    } catch (err) {
      console.error(err);
    }
  };

  const getHistTempData = async () => {
    try {
      const to = DateTime.now();
      const from = to.minus({ days: 1 });
      const weatherData = await AmbeeApiService.getHistWeather(
        EXAMPLE_LATITUDE,
        EXAMPLE_LONGITUDE,
        from,
        to,
      );

      weatherHistDataDailyRef.current = weatherData.data.history.map((entry) => ({
        date: DateTime.fromMillis(entry.time * 1000),
        temperature: entry.temperature,
      }));

      weatherHistDataMonthlyRef.current = Object.entries(
        weatherData.data.history.reduce((acc, val) => {
          const date = DateTime.fromMillis(val.time * 1000).startOf('month');
          acc[date.valueOf()] = [...(acc[date.valueOf()] ?? []), val];
          return acc;
        }, {} as { [key: number]: AmbeeApiWeatherHistDataEntry[] }),
      ).map(
        ([key, group]) =>
          ({
            date: DateTime.fromMillis(Number(key)),
            temperature: avg(group.map((e) => e.temperature)),
          } as TemperatureData),
      );
      return { daily: weatherHistDataDailyRef.current, monthly: weatherHistDataMonthlyRef.current };
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      // const tempData = await getHistTempData();
      const tempData = await getOpenMeteoData();
      await getDetailedEnergyData(tempData?.daily, tempData?.monthly);

      const energyDataIntervalCall = setInterval(async () => {
        await getDetailedEnergyData();
      }, energyRefreshIntervalMs);

      return () => {
        // clean up
        clearInterval(energyDataIntervalCall);
      };
    })();
  }, []);

  const [selectedType, setSelectedType] = useState<EnergyMeasureType>(
    EnergyMeasureType.AllEnergyUse,
  );

  const handleTypeChange = async (event: MouseEvent<HTMLButtonElement>) => {
    const newType = event.currentTarget.value;
    if (newType !== selectedType) {
      setSelectedType(newType as EnergyMeasureType);
    }
  };

  return (
    <div>
      <div className={twCls(display('hidden', 'lg:flex'), padding('pb-6'))}>
        My Home
        <span className={twCls(padding('px-2'))}>|</span>
        <span className={twCls(fontWeight('font-bold'))}>My Energy</span>
      </div>
      <div
        className={twCls(
          fontSize('text-3xl', 'md:text-5xl', 'lg:text-6xl'),
          padding('pb-4', 'md:pb-8', 'lg:pb-12'),
          fontWeight('font-medium'),
        )}
      >
        My Energy
      </div>

      {allEnergyWeek.length > 0 ? (
        <>
          <div
            className={twCls(
              display('flex'),
              flexDirection('flex-col', 'md:flex-row'),
              gap('gap-1'),
              padding('p-2'),
              borderWidth('border-b'),
              borderColor('border-grey-300'),
            )}
          >
            {EnergyMeasureTypeButtons.map(({ type, icon }, i) => (
              <button
                key={i}
                value={type}
                onClick={handleTypeChange}
                className={twCls(
                  backgroundColor(type === selectedType ? 'bg-white' : 'bg-grey-100'),
                  borderWidth(type === selectedType ? 'border' : 'border-0'),
                  borderColor('border-grey-400'),
                  borderRadius('rounded-lg'),
                  width('w-full'),
                  height('h-10'),
                  fontWeight('font-medium'),
                )}
              >
                <div
                  className={twCls(
                    display('flex'),
                    justifyContent('justify-center'),
                    alignItems('items-center'),
                    gap('gap-x-2'),
                  )}
                >
                  {icon}
                  {type}
                </div>
              </button>
            ))}
          </div>

          {selectedType === EnergyMeasureType.AllEnergyUse && (
            <EnergyUse weekData={allEnergyWeek} yearData={allEnergyYear} />
          )}
          {selectedType === EnergyMeasureType.Heating && (
            <EnergyUse weekData={heatEnergyWeek} yearData={heatEnergyYear} />
          )}
          {selectedType === EnergyMeasureType.Lighting && (
            <EnergyUse weekData={lightEnergyWeek} yearData={lightEnergyYear} />
          )}
          {selectedType === EnergyMeasureType.Sockets && (
            <EnergyUse weekData={socketsEnergyWeek} yearData={socketsEnergyYear} />
          )}

          {/* <div
            className={twCls(
              padding('p-6'),
              margin('mt-4'),
              borderWidth('border'),
              borderRadius('rounded-lg'),
              borderColor('border-grey-400'),
            )}
          >
            <ElectricSupplierForm />
          </div> */}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
