import { DateTime } from 'luxon';
import { EnvironmentalMonitorChartDatum } from 'models';

const HOURS_IN_A_DAY = 24;

export const sortPreviousTwentyFourHourPeriodChartData = (
  unsortedData: (readonly [DateTime, EnvironmentalMonitorChartDatum])[],
): EnvironmentalMonitorChartDatum[] => {
  // Separate by date if necessary
  const localDateToHourlyData = new Map<number, EnvironmentalMonitorChartDatum[]>();
  unsortedData.forEach(([localDateTime, datum]) => {
    const localDate = localDateTime.day;
    localDateToHourlyData.has(localDate)
      ? localDateToHourlyData.set(localDate, [
          ...(localDateToHourlyData.get(localDate) || []),
          datum,
        ])
      : localDateToHourlyData.set(localDate, [datum]);
  });
  const localDates: number[] = [];
  localDateToHourlyData.forEach((_, key) => {
    localDates.push(key);
  });
  localDates.sort();
  const sortedData: EnvironmentalMonitorChartDatum[] = [];
  localDates.forEach((localDate) => {
    const currentDateDataToSort = localDateToHourlyData.get(localDate);
    if (currentDateDataToSort) {
      const sortedDataForCurrentDate = currentDateDataToSort.sort(
        ({ timeIntervalLabel: a }, { timeIntervalLabel: b }) => +a - +b,
      );
      sortedDataForCurrentDate.forEach((datum) => sortedData.push(datum));
    }
  });
  return sortedData;
};

export const getPreviousTwentyFourHoursUTCDateRange = (
  excludeToday = true,
): { startUtcIsoDate: string; endUtcIsoDate: string } => {
  const startUtcIsoDate = excludeToday
    ? DateTime.utc()
        .minus({ hour: 2 * HOURS_IN_A_DAY })
        .toISODate()
    : DateTime.utc().minus({ hour: HOURS_IN_A_DAY }).toISODate();
  const endUtcIsoDate = excludeToday
    ? DateTime.utc()
        .minus({ hour: HOURS_IN_A_DAY - 1 })
        .toISODate()
    : // For explanation of the plus one hour here and the minus one hour from HOURS_IN_A_DAY above
      //  see note for endUtcHour in getPreviousTwentyFourHourPeriodUTCStartAndEndHours below.
      DateTime.utc().plus({ hour: 1 }).toISODate();

  return { startUtcIsoDate, endUtcIsoDate };
};

export const getPreviousTwentyFourHourPeriodUTCStartAndEndHours = (): {
  startUtcHour: number;
  endUtcHour: number;
} => {
  return {
    startUtcHour: DateTime.utc().minus({ hour: HOURS_IN_A_DAY }).hour,
    // The current hour plus one because this will show the hour interval containing the present moment as empty in the results,
    // when missing value is replaced this will yield a chart that shows an empty value for the present hour,
    // this makes clear that the hour displayed on the time interval axis is all readings that took place up
    // until the hour displayed on the time interval (x) axis of the chart.
    endUtcHour: DateTime.utc().plus({ hour: 1 }).hour,
  };
};

export const getChartDataForEachMissingUtcIsoDateTime = (
  setOfMissingExpectedUtcIsoDateTimes: Set<string>,
): (readonly [localDateTime: DateTime, datum: EnvironmentalMonitorChartDatum])[] => {
  const unsortedMissingExpectedChartData: (readonly [
    localDateTime: DateTime,
    datum: EnvironmentalMonitorChartDatum,
  ])[] = [];
  setOfMissingExpectedUtcIsoDateTimes.forEach((missingUtcIsoDateTime) => {
    const currentLocalDateTime = DateTime.fromISO(missingUtcIsoDateTime, { zone: 'utc' }).toLocal();
    const missingEntryChartDatum: EnvironmentalMonitorChartDatum = {
      // Hour property is zero indexed (0-23), so add 1 to show data labeled on chart as spanning from hours 1 to 24.
      timeIntervalLabel: currentLocalDateTime.hour + 1,
      avg: undefined,
      minMax: undefined,
    };
    unsortedMissingExpectedChartData.push([currentLocalDateTime, missingEntryChartDatum] as const);
  });
  return unsortedMissingExpectedChartData;
};

export const getSetOfExpectedUtcIsoDateTimesHourly = (
  startUtcIsoDate: string,
  startUtcHour: number,
  endUtcIsoDate: string,
  endUtcHour: number,
): Set<string> => {
  const setOfExpectedUtcIsoDateTimes = new Set<string>();
  const endUtcDateTime = DateTime.fromISO(endUtcIsoDate, { zone: 'utc' }).set({
    hour: endUtcHour,
  });
  let currentUtcDateTime = DateTime.fromISO(startUtcIsoDate, { zone: 'utc' }).set({
    hour: startUtcHour,
  });
  while (currentUtcDateTime.toUnixInteger() < endUtcDateTime.toUnixInteger()) {
    setOfExpectedUtcIsoDateTimes.add(currentUtcDateTime.toISO());
    currentUtcDateTime = currentUtcDateTime.plus({ hour: 1 });
  }
  return setOfExpectedUtcIsoDateTimes;
};
