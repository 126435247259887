// UNIT - Previous Thirty Days - Air quality averages

import { HistoricalAirQualityAverages, HistoricalEntry } from 'models';
import { baseManagementUrl } from 'services/apiConfig';
import { getPreviousThirtyDaysUTCDateRange, historialEnvironmentalDataApi } from '../sharedUtils';

export const getPerUnitPreviousThirtyDaysAverageAirQualityValues = async (
  unitId: string,
): Promise<HistoricalAirQualityAverages | undefined> => {
  const { startUtcIsoDate: startDate, endUtcIsoDate: endDate } =
    getPreviousThirtyDaysUTCDateRange();

  let existingHistoricalAirQualityAverages: HistoricalAirQualityAverages | undefined = undefined;
  const perUnitHistoricalEntries: HistoricalEntry[] | undefined =
    await historialEnvironmentalDataApi<HistoricalEntry[]>(
      `${baseManagementUrl}/units/${unitId}/historicaldata/air-quality/monthly?start_date=${startDate}&end_date=${endDate}`,
    );

  if (!perUnitHistoricalEntries) {
    return undefined;
  }

  perUnitHistoricalEntries?.forEach((entry) => {
    const currentHistoricalAirQualityAverages =
      getHistoricalAirQualityAveragesFromHistoricalEntry(entry);
    existingHistoricalAirQualityAverages = averageIntoExistingHistorialAirQualityAverages(
      currentHistoricalAirQualityAverages,
      existingHistoricalAirQualityAverages,
    );
  });

  return existingHistoricalAirQualityAverages;
};

const averageIntoExistingHistorialAirQualityAverages = (
  newHistoricalAirQualityAverages: HistoricalAirQualityAverages,
  existingHistoricalAirQualityAverages?: HistoricalAirQualityAverages,
): HistoricalAirQualityAverages => {
  if (!existingHistoricalAirQualityAverages) {
    return newHistoricalAirQualityAverages;
  }
  const mergedHistoricalAirQualityAverages: HistoricalAirQualityAverages = {
    ...existingHistoricalAirQualityAverages,
  };
  (<(keyof HistoricalAirQualityAverages)[]>(
    Object.keys(existingHistoricalAirQualityAverages)
  )).forEach((key) => {
    mergedHistoricalAirQualityAverages[key] =
      // Until more information (runningTotal and runningCount) are returned from the backend, division by two can be safely assumed
      // to correctly account for computation of the average here, this is because the previous thirty day period can, at most, span two
      // months, in which case, two entries are returned for the unit, for each month, respectively. As of now, the average of these two averages
      // is the best approximation of the actual average to return.
      // TODO: Re-work logic to correctly handle average computation when possible (when runningTotal and runningCount values are returned from backend)
      (existingHistoricalAirQualityAverages[key] + newHistoricalAirQualityAverages[key]) / 2;
  });
  return mergedHistoricalAirQualityAverages;
};

const getHistoricalAirQualityAveragesFromHistoricalEntry = (
  historicalEntry: HistoricalEntry,
): HistoricalAirQualityAverages => {
  const newHistoricalAirQualityAverages: HistoricalAirQualityAverages = {
    avg_co2: historicalEntry.co2.avg,
    avg_dew: historicalEntry.dew.avg,
    avg_rh: historicalEntry.rh.avg,
    avg_lux: historicalEntry.lux.avg,
    avg_voc: historicalEntry.voc.avg,
    avg_temp: historicalEntry.temp.avg,
    avg_air_pressure: historicalEntry.air_pressure.avg,
    avg_sound_pressure: historicalEntry.sound_pressure.avg,
    avg_pm1: historicalEntry.pm1.avg,
    avg_pm25: historicalEntry.pm25.avg,
    avg_pm4: historicalEntry.pm4.avg,
    avg_pm10: historicalEntry.pm10.avg,
  };
  return newHistoricalAirQualityAverages;
};
