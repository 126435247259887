import { RoomAirQualitySensorsData } from 'models/globalHomeModels';
import { EnvironmentSensorType } from '@mgh-app/component-library';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  combineCls,
  display,
  fontSize,
  height,
  justifyContent,
  margin,
  padding,
  textColor,
  TTailwindString,
  twCls,
  width,
} from 'style';
import { ReactComponent as UpIcon } from '../../assets/up.svg';
import { ReactComponent as DownIcon } from '../../assets/down.svg';
import { ReactComponent as Co2Icon } from '../../assets/co2.svg';
import { ReactComponent as HumidityIcon } from '../../assets/humidity.svg';
import { ReactComponent as VocIcon } from '../../assets/voc.svg';
import { ReactComponent as ParticulatesIcon } from '../../assets/particulates.svg';
import { ReactComponent as TemperatureIcon } from '../../assets/temperature.svg';

const getUnit = (type: EnvironmentSensorType): string => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      return 'ppm';
    case EnvironmentSensorType.VOC:
      return 'ppb';
    case EnvironmentSensorType.RH:
      return '%';
    case EnvironmentSensorType.Temperature:
      return '°C';
    case EnvironmentSensorType.Particulates:
    case EnvironmentSensorType.PM1:
    case EnvironmentSensorType.PM25:
    case EnvironmentSensorType.PM4:
    case EnvironmentSensorType.PM10:
      return 'µg/m³';
    default:
      throw new Error('Unknown sensor type');
  }
};

const getIcon = (type: EnvironmentSensorType) => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      return <Co2Icon />;
    case EnvironmentSensorType.VOC:
      return <VocIcon />;
    case EnvironmentSensorType.RH:
      return <HumidityIcon />;
    case EnvironmentSensorType.Temperature:
      return <TemperatureIcon />;
    case EnvironmentSensorType.Particulates:
    case EnvironmentSensorType.PM1:
    case EnvironmentSensorType.PM25:
    case EnvironmentSensorType.PM4:
    case EnvironmentSensorType.PM10:
      return <ParticulatesIcon />;
    default:
      throw new Error('Unknown sensor type');
  }
};

const getLabelColor = (
  type: EnvironmentSensorType | undefined,
  label: string | undefined,
): TTailwindString => {
  switch (type) {
    case EnvironmentSensorType.CO2:
      switch (label) {
        case 'GOOD':
          return twCls(backgroundColor('bg-green-100'));
        case 'POOR':
          return twCls(backgroundColor('bg-amber-100'));
        case 'WARNING':
          return twCls(backgroundColor('bg-amber-200'));
        default:
          return twCls(backgroundColor('bg-green-100'));
      }
    case EnvironmentSensorType.VOC:
      switch (label) {
        case 'LOW':
        case 'ACCEPTABLE':
          return twCls(backgroundColor('bg-green-100'));
        case 'MARGINAL':
          return twCls(backgroundColor('bg-amber-100'));
        case 'HIGH':
        case 'VERY HIGH':
          return twCls(backgroundColor('bg-amber-200'));
        default:
          return twCls(backgroundColor('bg-green-100'));
      }
    case EnvironmentSensorType.RH:
      switch (label) {
        case 'DRY':
          return twCls(backgroundColor('bg-amber-100'));
        case 'IDEAL':
          return twCls(backgroundColor('bg-green-100'));
        case 'HIGH':
          return twCls(backgroundColor('bg-amber-200'));
        default:
          return twCls(backgroundColor('bg-green-100'));
      }
    case EnvironmentSensorType.Temperature:
      switch (label) {
        default:
          return twCls(backgroundColor('bg-green-100'));
      }
    case EnvironmentSensorType.Particulates:
    case EnvironmentSensorType.PM1:
    case EnvironmentSensorType.PM25:
    case EnvironmentSensorType.PM4:
    case EnvironmentSensorType.PM10:
      switch (label) {
        case 'GOOD':
          return twCls(backgroundColor('bg-green-100'));
        case 'MODERATE':
          return twCls(backgroundColor('bg-amber-100'));
        case 'HIGH':
        case 'VERY HIGH':
          return twCls(backgroundColor('bg-amber-200'));
        default:
          return twCls(backgroundColor('bg-green-100'));
      }

    default:
      return twCls(backgroundColor('bg-green-100'));
  }
};

export interface EnvironmentMonitoringItemProps {
  title: string;
  sensorType: EnvironmentSensorType;
  peakSensor: RoomAirQualitySensorsData | undefined;
  minSensor: RoomAirQualitySensorsData | undefined;
}

export const EnvironmentMonitoringItem = ({
  title,
  sensorType,
  peakSensor,
  minSensor,
}: EnvironmentMonitoringItemProps): JSX.Element => {
  return (
    <div
      className={twCls(
        padding('p-4'),
        borderWidth('border'),
        borderRadius('rounded-lg'),
        borderColor('border-grey-400'),
      )}
    >
      <div className={twCls(display('flex'), alignItems('items-center'), height('h-10'))}>
        {getIcon(sensorType)}
        <div className={twCls(fontSize('text-lg'), margin('ml-2'))}>{title}</div>
      </div>
      <div className={twCls(display('flex'), justifyContent('justify-between'), margin('mt-4'))}>
        <div className={twCls(fontSize('text-sm'))}>
          {peakSensor?.value !== undefined ? `${peakSensor.value} ${getUnit(sensorType)}` : 'N/A'}
        </div>
        <div className={twCls(fontSize('text-xs'))}>Peak</div>
      </div>
      <div
        className={combineCls(
          getLabelColor(peakSensor?.type, peakSensor?.label),
          twCls(width('w-full'), height('h-7')),
          display('flex'),
          justifyContent('justify-between'),
        )}
      >
        <div
          className={twCls(
            display('flex'),
            justifyContent('justify-start'),
            alignItems('items-center'),
            padding('pl-1'),
            textColor('text-white'),
          )}
        >
          {peakSensor?.roomName}
        </div>
        <div
          className={twCls(display('flex'), justifyContent('justify-end'), padding('pt-2', 'pr-2'))}
        >
          <UpIcon />
        </div>
      </div>
      <div className={twCls(height('h-7'))} />
      <div
        className={combineCls(
          getLabelColor(minSensor?.type, minSensor?.label),
          twCls(width('w-full'), height('h-7')),
          display('flex'),
          justifyContent('justify-between'),
        )}
      >
        <div
          className={twCls(
            display('flex'),
            justifyContent('justify-start'),
            alignItems('items-center'),
            padding('pl-1'),
            textColor('text-white'),
          )}
        >
          {minSensor?.roomName}
        </div>
        <div
          className={twCls(display('flex'), justifyContent('justify-end'), padding('pt-2', 'pr-2'))}
        >
          <DownIcon />
        </div>
      </div>
      <div className={twCls(display('flex'), justifyContent('justify-between'))}>
        <div className={twCls(fontSize('text-sm'))}>
          {minSensor?.value !== undefined ? `${minSensor.value} ${getUnit(sensorType)}` : 'N/A'}
        </div>
        <div className={twCls(fontSize('text-xs'))}>Minimum</div>
      </div>
    </div>
  );
};
