import { Spinner } from '@mgh-app/component-library';
import { useCallback, useEffect, useState } from 'react';
import { AirQualitySensorsContainer } from 'components/AirQualitySensorsContainer';
import { RoomAirQualitySensorsData, RoomData } from 'models/globalHomeModels';
import { GlobalHomeService } from 'services/GlobalHomeService';
import { display, flexDirection, justifyContent, padding, twCls } from 'style';
import { SensorsUtils } from '../../../utils';
import { getAllRoomsAirQualitySensorData } from 'services';

const airQualityRefreshIntervalMs = 30000; // 30 secs

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EnvironmentalMonitoringPanelProps {}

export const EnvironmentalMonitoringPanel =
  ({}: EnvironmentalMonitoringPanelProps): JSX.Element => {
    const [airQualitySensorsData, setAirQualitySensorsData] = useState<
      RoomAirQualitySensorsData[] | null
    >(null);

    const extractAirQualSensorDataFromRoomData = useCallback(async (roomData: RoomData[] | undefined) => {
      if (!roomData || !roomData?.length) {
        return;
      }

      const sensorsData: RoomAirQualitySensorsData[] = [];
      for (const room of roomData) {
        if (room.airQualitySensorsId) {
          const sensors = await GlobalHomeService.getSensors(room.airQualitySensorsId);
          const airQualitySensors = SensorsUtils.convert(sensors.data);
          const roomAirQualitySensors: RoomAirQualitySensorsData[] = [];
          airQualitySensors.forEach((s) =>
            roomAirQualitySensors.push({
              type: s.type,
              value: s.value,
              label: s.label,
              roomId: room.roomId,
              roomName: room.roomName,
            }),
          );
          if (roomAirQualitySensors) {
            sensorsData.push(...roomAirQualitySensors);
          }
        }
      }

      setAirQualitySensorsData(sensorsData);
    }, [setAirQualitySensorsData]);

    useEffect(() => {
      (async () => {
        const roomData = await getAllRoomsAirQualitySensorData();
        await extractAirQualSensorDataFromRoomData(roomData);

        const airQualitySensorsDataIntervalCall = setInterval(async () => {
          await extractAirQualSensorDataFromRoomData(roomData);
        }, airQualityRefreshIntervalMs);

        return () => {
          // clean up
          clearInterval(airQualitySensorsDataIntervalCall);
        };
      })();
    }, [extractAirQualSensorDataFromRoomData]);

    return (
      <div
        className={twCls(
          display('flex'),
          flexDirection('flex-col'),
          justifyContent('justify-start'),
          padding('py-6'),
        )}
      >
        {airQualitySensorsData ? (
          <AirQualitySensorsContainer sensorsData={airQualitySensorsData} />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };
