import { NavLink } from 'react-router-dom';
import {
  combineCls,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  padding,
  textColor,
  twCls,
} from 'style';

export const navPages = [
  {
    key: 'Dashboard',
    path: '/dashboard',
    pageDisplayName: 'Dashboard',
    isRoot: true,
  },
  {
    key: 'MyHome',
    path: '/my-home',
    pageDisplayName: 'My Home',
    isRoot: true,
  },
  {
    key: 'MyEnergy',
    path: '/my-home/my-energy',
    pageDisplayName: 'My Energy',
    isRoot: false,
  },
  {
    key: 'EnvironmentalMonitoring',
    path: '/my-home/environmental-monitoring',
    pageDisplayName: 'Environmental Monitoring',
    isRoot: false,
  },
  {
    key: 'UltraWebApp',
    path: '/ultra-web-app',
    pageDisplayName: 'Ultra Web App',
    isRoot: true,
  },
  // {
  //   key: 'InsuranceWarranty',
  //   path: '/#',
  //   pageDisplayName: 'Insurance & Warranty',
  //   isRoot: false,
  // },
  // {
  //   key: 'UserGuides',
  //   path: '/#',
  //   pageDisplayName: 'User Guides',
  //   isRoot: false,
  // },
  // {
  //   key: 'ConstructionInformation',
  //   path: '/#',
  //   pageDisplayName: 'Construction Information',
  //   isRoot: false,
  // },
  // { key: 'MyInformation', path: '/#', pageDisplayName: 'My Information', isRoot: true },
  { key: 'MyAccount', path: '/my-account', pageDisplayName: 'My Account', isRoot: true },
];

const navRootLinkClassName = twCls(padding('pt-3'));
const activeRootLinkClassName = combineCls(navRootLinkClassName, twCls(fontWeight('font-black')));
const setActiveRootLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? activeRootLinkClassName : navRootLinkClassName;

const navChildLinkClassName = twCls(padding('pl-3'));
const activeChildLinkClassName = combineCls(navChildLinkClassName, twCls(fontWeight('font-black')));
const setActiveChildLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? activeChildLinkClassName : navChildLinkClassName;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SidebarProps {}

export const Sidebar = (): JSX.Element => {
  return (
    <div className={twCls(display('flex'), flexDirection('flex-col'), padding('px-4'))}>
      <div
        className={twCls(padding('pt-11', 'pb-6'), fontSize('text-3xl'), fontWeight('font-light'))}
      >
        User Log Book
      </div>
      <nav
        className={twCls(
          display('flex'),
          flexDirection('flex-col'),
          textColor('text-blue-100'),
          fontSize('text-lg'),
          fontWeight('font-medium'),
        )}
      >
        {navPages.map(({ key, path, pageDisplayName, isRoot }) => (
          <NavLink
            key={key}
            to={path}
            className={isRoot ? setActiveRootLinkClassName : setActiveChildLinkClassName}
            end={true}
          >
            {pageDisplayName}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};
